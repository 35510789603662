/**@param {String} key * @param {any} any */
function localStore(key, any) {
    localStorage[key] = JSON.stringify(any);
}

/**@param {String} key */
function localLoad(key) {
    var str = localStorage[key] || 'null';
    return JSON.parse(str);
}

/**@param {String} key * @param {any} [any] */
function sessionStore(key, any) {
    sessionStorage.setItem(key, JSON.stringify(any));
}

/**@param {String} key */
function sessionLoad(key) {
    return JSON.parse(sessionStorage.getItem(key));
}

export {
    localStore,
    localLoad,
    sessionStore,
    sessionLoad
}