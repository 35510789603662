<template>
	<md-option class="option-container" :class="optsContainerClasses" :value="value">
		{{ label }}
		<span v-if="isSideLabel" class="side-label" :style="sideLabelStyle"></span>
		<img v-if="src" :src="src" />
	</md-option>
</template>

<script>
export default {
	name: 'Option',
	props: {
		value: {
			required: true
		},
		label: {
			required: true
		},
		src: { default: '' },
		sideLabelStyle: { default: '' },
		isSideLabel: { default: false }
	},
	computed: {
		optsContainerClasses() {
			return {
				'with-img': this.src.length,
				'option-side-labels': this.isSideLabel && this.sideLabelStyle.length
			}
		}
	}
}
</script>

<style lang="scss" scoped>
img {
	width: 2vw !important;
	margin-inline-end: 20px;
}
.side-label:after {
	content: '\00a0';
}
</style>
