<template>
  <div class="screen"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background-color: $grey;
  opacity: 0.4;
}
</style>