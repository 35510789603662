import ajax from '../../common/services/httpService'
import axios from 'axios'
axios.defaults.withCredentials = true
const BASE_URL = 'site'

/**@typedef {{name: String; desc: String; company_id: String | Number;}} Site*/

/**@typedef {{
 * companyName: String;
 * siteName: String;
 * siteDesc: String;
 * }} SiteDetails*/

/**@returns {Promise<Site[]>} */
async function getSites() {
  const sites = await ajax.get(BASE_URL)
  return sites
}

/**@param {Site} site */
async function addSite(site) {
  const res = await ajax.post(BASE_URL, site)
  return res
}

/**@param {Site} site*/
async function updateSite(site) {
  const res = await ajax.put(BASE_URL, site)
  return res
}

/**@param {Number | String} id */
async function removeSite(id) {
  const res = await ajax.delete(BASE_URL, id)
  return res
}

/**@param {Number | String} id
 * @returns {Promise<Site>} */
async function getSiteById(id) {
  const site = await ajax.get(`${BASE_URL}/${id}`)
  return site
}

/**@param {Number | String} id
 * @returns {Promise<SiteDetails>}*/
async function getSiteDetails(id) {
  //TOM: doesn't support sites without service provider currently
  const site = await ajax.get(`${BASE_URL}/details/${id}`)
  return site
}

async function getServiceProviders() {
  const serviceProviders = await ajax.get(`${BASE_URL}/serviceProviders`)
  return serviceProviders
}

async function getSectors() {
  const sectors = await ajax.get(`${BASE_URL}/sectors`)
  return sectors
}

/**@param {Number | String} id */
async function getServiceProvidersBySiteId(id) {
  const serviceProviders = await ajax.get(`serviceProvider/site/${id}`)
  return serviceProviders
}

async function getSiteRooms(id) {
  const rooms = await ajax.get(`${BASE_URL}/siteRooms/${id}`)
  return rooms
}

async function getSiteQRDevices(id) {
  const devices = await ajax.get(`${BASE_URL}/QRDevices/${id}`)
  return devices
}

async function getSiteOffDays(id) {
  const offdays = await ajax.get(`${BASE_URL}/offdays/${id}`)
  return offdays
}

async function updateSiteOffDays(id, offdays) {
  return await ajax.put(`${BASE_URL}/offdays/${id}`, offdays)
}

async function addReflactiveShifts(siteId, companyId, language) {
  return await ajax.post(`${BASE_URL}/shifts/${siteId}`, { companyId, utcOffset: new Date().getTimezoneOffset() / 60, language })
}

async function removeReflactiveShifts(siteId, companyId) {
  return await ajax.delete(`${BASE_URL}/shifts/${siteId}`, { companyId })
}

function getDefaultSite() {
  return {
    name: '',
    company_id: '',
    type: 'operation',
    status: 'active',
    sector: '',
    cleaningServiceProviderId: '5',
    managementServiceProviderId: '4',
    is_time_save: true,
    exposures: true,
    alerts: true,
    embedded_link: null,
  }
}

async function getHolidayList(country = 'il', year = new Date().getFullYear()) {
  const BASE_URL = 'https://www.googleapis.com/calendar/v3/calendars'
  const BASE_CALENDAR_ID = 'holiday%40group.v.calendar.google.com/events'
  const API_KEY = 'AIzaSyBa8IoX5_rAq5HUqxzaHbWaSK4Cl3Td9VM'

  const langs = ['en']

  const googleCalendar = {
    ad: 'ad',
    ae: 'ae',
    af: 'af',
    ag: 'ag',
    ai: 'ai',
    al: 'al',
    am: 'am',
    ao: 'ao',
    ar: 'ar',
    as: 'as',
    at: 'austrian',
    au: 'australian',
    aw: 'aw',
    az: 'az',
    ba: 'ba',
    bb: 'bb',
    bd: 'bd',
    be: 'be',
    bf: 'bf',
    bg: 'bulgarian',
    bh: 'bh',
    bi: 'bi',
    bj: 'bj',
    bl: 'bl',
    bm: 'bm',
    bn: 'bn',
    bo: 'bo',
    br: 'brazilian',
    bs: 'bs',
    bt: 'bt',
    bw: 'bw',
    by: 'by',
    bz: 'bz',
    ca: 'canadian',
    cd: 'cd',
    cf: 'cf',
    cg: 'cg',
    ch: 'ch',
    ci: 'ci',
    ck: 'ck',
    cl: 'cl',
    cm: 'cm',
    cn: 'china',
    co: 'co',
    cr: 'cr',
    cu: 'cu',
    cv: 'cv',
    cw: 'cw',
    cy: 'cy',
    cz: 'czech',
    de: 'german',
    dj: 'dj',
    dk: 'danish',
    dm: 'dm',
    do: 'do',
    dz: 'dz',
    ec: 'ec',
    ee: 'ee',
    eg: 'eg',
    er: 'er',
    es: 'spain',
    et: 'et',
    fi: 'finnish',
    fj: 'fj',
    fk: 'fk',
    fm: 'fm',
    fo: 'fo',
    fr: 'french',
    ga: 'ga',
    gb: 'uk',
    gd: 'gd',
    ge: 'ge',
    gf: 'gf',
    gg: 'gg',
    gh: 'gh',
    gi: 'gi',
    gl: 'gl',
    gm: 'gm',
    gn: 'gn',
    gp: 'gp',
    gq: 'gq',
    gr: 'greek',
    gt: 'gt',
    gu: 'gu',
    gw: 'gw',
    gy: 'gy',
    hk: 'hong_kong',
    hn: 'hn',
    hr: 'croatian',
    ht: 'ht',
    hu: 'hungarian',
    id: 'indonesian',
    ie: 'irish',
    il: 'jewish',
    im: 'im',
    in: 'indian',
    iq: 'iq',
    ir: 'ir',
    is: 'is',
    it: 'italian',
    je: 'je',
    jm: 'jm',
    jo: 'jo',
    jp: 'japanese',
    ke: 'ke',
    kg: 'kg',
    kh: 'kh',
    ki: 'ki',
    km: 'km',
    kn: 'kn',
    kp: 'kp',
    kr: 'south_korea',
    kw: 'kw',
    ky: 'ky',
    kz: 'kz',
    la: 'la',
    lb: 'lb',
    lc: 'lc',
    li: 'li',
    lk: 'lk',
    lr: 'lr',
    ls: 'ls',
    lt: 'lithuanian',
    lu: 'lu',
    lv: 'latvian',
    ly: 'ly',
    ma: 'ma',
    mc: 'mc',
    md: 'md',
    me: 'me',
    mf: 'mf',
    mg: 'mg',
    mh: 'mh',
    mk: 'mk',
    ml: 'ml',
    mm: 'mm',
    mn: 'mn',
    mo: 'mo',
    mp: 'mp',
    mq: 'mq',
    mr: 'mr',
    ms: 'ms',
    mt: 'mt',
    mu: 'mu',
    mv: 'mv',
    mw: 'mw',
    mx: 'mexican',
    my: 'malaysia',
    mz: 'mz',
    na: 'na',
    nc: 'nc',
    ne: 'ne',
    ng: 'ng',
    ni: 'ni',
    nl: 'dutch',
    no: 'norwegian',
    np: 'np',
    nr: 'nr',
    nz: 'new_zealand',
    om: 'om',
    pa: 'pa',
    pe: 'pe',
    pf: 'pf',
    pg: 'pg',
    ph: 'philippines',
    pk: 'pk',
    pl: 'polish',
    pm: 'pm',
    pr: 'pr',
    pt: 'portuguese',
    pw: 'pw',
    py: 'py',
    qa: 'qa',
    re: 're',
    ro: 'romanian',
    rs: 'rs',
    ru: 'russian',
    rw: 'rw',
    sa: 'saudiarabian',
    sb: 'sb',
    sc: 'sc',
    sd: 'sd',
    se: 'swedish',
    sg: 'singapore',
    sh: 'sh',
    si: 'slovenian',
    sk: 'slovak',
    sl: 'sl',
    sm: 'sm',
    sn: 'sn',
    so: 'so',
    sr: 'sr',
    ss: 'ss',
    st: 'st',
    sv: 'sv',
    sx: 'sx',
    sy: 'sy',
    sz: 'sz',
    tc: 'tc',
    td: 'td',
    tg: 'tg',
    th: 'th',
    tj: 'tj',
    tl: 'tl',
    tm: 'tm',
    tn: 'tn',
    to: 'to',
    tr: 'turkish',
    tt: 'tt',
    tv: 'tv',
    tw: 'taiwan',
    tz: 'tz',
    uk: 'ukrainian',
    ug: 'ug',
    us: 'usa',
    uy: 'uy',
    uz: 'uz',
    va: 'va',
    vc: 'vc',
    ve: 've',
    vg: 'vg',
    vi: 'vi',
    vn: 'vietnamese',
    vu: 'vu',
    wf: 'wf',
    ws: 'ws',
    xk: 'xk',
    ye: 'ye',
    yt: 'yt',
    za: 'sa',
    zm: 'zm',
    zw: 'zw',
  }

  const minTime = new Date(`${year}-01-01`).toISOString()
  const maxTime = new Date(`${year}-12-31`).toISOString()

  const url = `${BASE_URL}/${langs[0]}.${googleCalendar[country]}%23${BASE_CALENDAR_ID}?key=${API_KEY}&timeMin=${minTime}&timeMax=${maxTime}`

  try {
    let holidays = await (await fetch(url)).json()
    return holidays.items.map(({ summary, start, end }) => ({ name: summary, start: start.date, end: end.date, shiftIds: [] })).sort(({ start: date1 }, { start: date2 }) => new Date(date1).getTime() - new Date(date2).getTime())
  } catch (err) {
    throw err
  }
}

export default {
  getSites,
  addSite,
  updateSite,
  removeSite,
  getSiteById,
  getSiteDetails,
  getServiceProviders,
  getServiceProvidersBySiteId,
  getSectors,
  getDefaultSite,
  getSiteRooms,
  getSiteQRDevices,
  getHolidayList,
  getSiteOffDays,
  updateSiteOffDays,
  addReflactiveShifts,
  removeReflactiveShifts,
}
