import Swal from "sweetalert2";
import scssVars from '../../../styles/vars.scss';

function errorMsg(text = "Something went wrong!") {
    return Swal.fire({
        icon: "error",
        title: "Oops...",
        text
      });
}

function deletedMsg() {
    return Swal.fire("Deleted!", "Your file has been deleted.", "success");
}

function confirmMsg() {
    return Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: scssVars.blue,
        cancelButtonColor: scssVars.red,
        confirmButtonText: "Yes!"
      });
}

function savedMsg(title = 'Your work has been saved', extra) {
    if(extra){
        title += `\n${extra}`
    }
    return Swal.fire({
        position: 'center',
        icon: 'success',
        title,
        showConfirmButton: false,
        timer: 1500
      })
}

export default {
    errorMsg,
    confirmMsg,
    deletedMsg,
    savedMsg
}