import Vue from 'vue'
import Router from 'vue-router'
import { overviewRoutes } from './modules/performance/routes/dashboardRoutes'
import { surveysRoutes } from './modules/surveys/routes/surveysRoutes'
import { adminRoutes } from './modules/control-panel/routes/adminRoutes'
import { authRoutes } from './modules/auth/routes/authRoutes'
import { realTimeRoutes } from './modules/real-time/routes/realTimeRoutes'
import { workingAreaRoutes } from './modules/working-area/routes/workingAreaRoutes'
import { taskRoutes } from './modules/task/routes/taskRoutes'
import { usersRoutes } from './modules/users/routes/usersRoutes'
import { shiftRoutes } from './modules/shift/routes/shiftRoutes'
import store from './store'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		...overviewRoutes,
		...surveysRoutes,
		...authRoutes,
		...adminRoutes,
		...realTimeRoutes,
		...shiftRoutes,
		...workingAreaRoutes,
		...taskRoutes,
		...usersRoutes,
		{ path: '/staff', name: 'staff' }
	]
})

router.beforeEach(async (to, from, next) => {
	// Redirect to login if the route doesn't have a name
	if (!to.name) return next('/login')

	// Allow direct access to the login route
	if (to.name === 'login') return next()

	// Check if the user is already logged in
	let user = store.getters.loggedUser

	// If the user is not logged in, attempt to retrieve the logged-in user
	if (!user) {
		await store.dispatch('getLoggedInUser')
		user = store.getters.loggedUser
		if (user) {
			// Found a logged-in user, load the filter options and selection
			await store.dispatch('loadFilterOptionsAndSelection')
		}
	}

	// Redirect to login if no user is found
	if (!user) return next('/login')

	// Redirect from home to dashboard if user is logged in
	if (to.path === '/') return next('/dashboard')

	// Check if the user has the necessary roles to access the route
	let hasViewPermission = user.roles.some((role) => to.path.split('/').includes(role))

	// Allow access to 'working-area' if the user has 'real-time' role
	if (to.path.split('/').includes('working-area') && user.roles.includes('real-time')) {
		hasViewPermission = true
	}

	// Redirect to the target route if the user has permission, otherwise redirect to login
	return hasViewPermission ? next() : next('/login')
})

export default router
