import ajax from '../../common/services/httpService';
import { localeData } from '../../common/services/localeService';
import chartService from './performanceChartService';

export {
    getOverviewData,
    getOverviewSums,
    getAnalyticsData,
    getRoomDataByHour,
    getRoomById,
    getTopIssues,
    getAnalyticsSums,
    getRoomCleanerArrivals,
    getRoomExposures,
    getRoomsCounterCallsSum,
    getRoomsCleandexCallsSum,
    getRoomsManualCallsSum
};

export default {
    trendInfoForBarItems,
    getRoomCleanerArrivalsBySiteId,
    getRoomSupervisorArrivalsBySiteId,
    getAnalysisReport
};

function trendInfoForBarItems(overviewData) {
    const trendArrowNames = ['avg_score','exposures', 'complaints', 'maintenanceComplaint', 'response_time_SLA', 'time_saved'];
    /**@type {Object<string, { text: String; upDown: 'up' | 'down' }>} */
    const trendInfo = trendArrowNames.reduce((acc, name) => {
        if (!overviewData[name] || !overviewData[name].length) return acc;
        acc[name] = {};
        const values = overviewData[name].map(a => +a.count);
        const { start, end } = chartService.trendStartEnd(values);
        const diff = start - end;
        const text = `${Math.abs(((diff / start) * 100)).toFixed(1)}%`;
        (start < end) ? acc[name].upDown = 'up' : acc[name].upDown = 'down';
        acc[name].text = text;
        return acc;
    }, {});
    return trendInfo;
}

/**
 * @param {Number | String} roomId
 * @param {{ timeFrom?: String; timeTo?: String; }} query
*/
async function getRoomCleanerArrivals(roomId, filter) {
    /**@type {Array<string>[]} - matrix of utc date string*/
    const cleanerArrivalData = await ajax.get(`stats/room/cleaner-arrival/${roomId}`, null, filter);
    cleanerArrivalData.forEach((item, index) => {
        item.forEach((arrival, index) => {
            if (arrival.time) {
                const date = new Date(arrival.time)
                arrival.time = new Date(date.toISOString().slice(0, -1))
            }
        })
    })
    return cleanerArrivalData;
}

/**
 * @param {Number | String} roomId
 * @param {{companyId:String, siteId:Number, timeFrom?: String; timeTo?: String; }} query
*/
async function getRoomExposures(roomId, filter) {
    /**@type {Array<string>[]} - matrix of utc date string*/
    const roomExposuresData = await ajax.get(`stats/room/room-exposures/${roomId}`, null, filter);
    // To convert the string dates to Date objects
    roomExposuresData.forEach((item, index) => {
        item.forEach((exposure, index) => {
            if (exposure.endTime) {
                const date = new Date(exposure.endTime)
                exposure.endTime = new Date(date.toISOString().slice(0, -1))
            }
            if (exposure.startTime) {
                const date = new Date(exposure.startTime)
                exposure.startTime = new Date(date.toISOString().slice(0, -1))
            }
        })
    })
    return roomExposuresData
}

/**
 * @param {Number | String} siteId
 * @param {{ timeFrom?: String; timeTo?: String; }} query
*/
async function getRoomCleanerArrivalsBySiteId(siteId, query = {}) {
    /**@type {Object<string, Array<string>[]>} - matrix of utc date string by room id*/
    const cleanerArrivalData = await ajax.get(`stats/room/site-cleaner-arrival/${siteId}`, null, query);
    return cleanerArrivalData;
}

/**
 * @param {Number | String} siteId
 * @param {{ timeFrom?: String; timeTo?: String; }} query
*/
async function getRoomSupervisorArrivalsBySiteId(siteId, query = {}) {
    const supervisorArrivalData = await ajax.get(`stats/room/site-supervisor-arrival/${siteId}`, null, query);
    return supervisorArrivalData;
}

/**@param { String | Number } id * @param {Object} filter*/
async function getRoomDataByHour(id, filter) {
    const dataByHour = await ajax.get(`stats/room/hourData/${id}`, null, filter);
    return dataByHour;
}

async function getRoomById(id) {
    const room = await ajax.get(`room/${id}`);
    return room;
}

async function getTopIssues(filter) {
    filter.language = localeData.language
    const issues = await ajax.get('suggestions/topSuggestions', null, filter);
    for (const field in issues) {
        issues[field] = issues[field].map(complaint => {
            return {
                ...complaint,
                hour: complaint.hour,
            }
        })
    }
    return issues;
}

async function getAnalyticsData(filter) {
    filter.language = localeData.language
    const analyticsData = await ajax.get('stats/performance/analytics', null, filter);
    if (!analyticsData) return analyticsData;
    const localeHourData = analyticsData.hourData.map(_sortLocaleHours).sort((a, b) => a.time - b.time);
    analyticsData.hourData = localeHourData;
    return analyticsData;
}

function _sortLocaleHours(hourItem) {
    const initialDate = new Date();
    initialDate.setHours(hourItem.time)
    const localHour = initialDate.getHours();
    hourItem.time = localHour;
    return hourItem;
}

async function getOverviewData(filter) {
    const overviewData = await ajax.get('stats/performance/overview', null, filter);
    return overviewData;
}

function getOverviewSums(overviewData) {
    let sum = { 
            visits: 0,
            exposures:0,
            complaints: 0,
            response_time_SLA: 0,
            avg_score: 0,
            time_saved: 0,
            est_ROI: 0,
            complaints_vs_visits: 0,
            cleaner_arrivals: 0,
            supervisor_arrival: 0,
            isAmended: 0
        }
    let complaintVSvisitsInfinityCount = 0
    let exposuresCount = 0;

    //all arrays have the same length
    overviewData.visits.forEach((item, index) => {
        if (item.isAmended) sum.isAmended = 1
        sum.visits += item.count;
        sum.complaints += overviewData.complaints[index].count;
        sum.cleaner_arrivals += overviewData.arrivals[index].count;
        sum.response_time_SLA += overviewData.response_time_SLA[index].count;
        sum.avg_score += overviewData.avg_score[index].count;
        sum.exposures += overviewData.exposures[index].count;
        sum.time_saved += overviewData.time_saved[index].count;
        sum.supervisor_arrival += overviewData.supervisor_arrival[index].count;
        sum.est_ROI += overviewData.est_ROI[index].count;

        if(overviewData.exposures[index].count)exposuresCount++;
        if (overviewData.complaints_vs_visits[index].count === 'Infinity') {
            complaintVSvisitsInfinityCount++
        } else {
            sum.complaints_vs_visits += +overviewData.complaints_vs_visits[index].count
        }
    })
    //avg is for each one, so it should be devided by total
    const { response_time_SLA, avg_score } = overviewData
    const lenOfDaysWithResponseTime = _getLengthOfDaysWithData(response_time_SLA)
    sum.response_time_SLA = sum.response_time_SLA / lenOfDaysWithResponseTime
    sum.response_time_SLA = Math.round(sum.response_time_SLA);

    const lenOfDaysWithScore = _getLengthOfDaysWithData(avg_score)
    sum.avg_score = sum.avg_score / lenOfDaysWithScore
    sum.avg_score = Math.round(sum.avg_score);

    sum.exposures = Math.round((sum.exposures / exposuresCount));

    sum.visits = Math.floor(sum.visits);

    // sum.complaints_vs_visits = sum.complaints_vs_visits / (overviewData.complaints_vs_visits.length - complaintVSvisitsInfinityCount);
    // sum.complaints_vs_visits = sum.complaints_vs_visits.toFixed(3);

    // Customer success team asked to change how we calculate the complaints_vs_visits
    sum.complaints_vs_visits = ((sum.complaints / sum.visits) * 100).toFixed(3)
    
    return sum;
}

function getAnalyticsSums(hourData) {
    const sums = hourData.reduce((acc, item) => {
        const complaintSum = item.complaints;
        if (item.isAmended) { acc.isAmended = 1 }
        (acc.visits || acc.visits === 0) ? acc.visits += item.visits : acc.visits = item.visits;
        (acc.arrivals || acc.arrivals === 0) ? acc.arrivals += item.arrivals : acc.arrivals = item.arrivals;
        (acc.maintenanceArrivals || acc.maintenanceArrivals === 0) ? acc.maintenanceArrivals += item.maintenanceArrivals : acc.maintenanceArrivals = item.maintenanceArrivals;
        (acc.maintenanceComplaint || acc.maintenanceComplaint === 0) ? acc.maintenanceComplaint += item.maintenanceComplaint : acc.maintenanceComplaint = item.maintenanceComplaint;
        (acc.complaints || acc.complaints === 0) ? acc.complaints += complaintSum : acc.complaints = complaintSum;
        return acc;
    }, {});
    return sums;
}

function getRoomsCounterCallsSum(rooms) {
    let counterCalls = 0
    rooms.forEach(room => counterCalls += room.counterCalls)
    return counterCalls
}

function getRoomsCleandexCallsSum(rooms) {
    let cleandexCalls = 0
    rooms.forEach(room => cleandexCalls += room.cleandexCalls)
    return cleandexCalls
}

function getRoomsManualCallsSum(rooms) {
    let manualCalls = 0
    rooms.forEach(room => manualCalls += room.manualCalls)
    return manualCalls
}

function _getLengthOfDaysWithData(data) {
    return data.filter(item => item.count !== null).length
}

async function getAnalysisReport(filter) {
    const report = await ajax.get('stats/performance/analysis-report', null, filter)
    return report
}