import ajax from './httpService';
import { localeData } from './localeService'
const BASE_URL = 'filter';

/**
 * @typedef {'custom_time' | 'last_week' | 'last_month' | 'last_year' | 'last_day' | 'last_quarter'} TimeType
 * @typedef {import('../models/Filter').FilterOptions} FilterOptions
 */

export {
    getCompanyGeneralData,
    getCompaniesAndSites,
    getRoomListWithRoomDetails
}

export default { getSiteCompanyList, getFilterOptions };


async function getSiteCompanyList() {
    /**@type {{ id:number | string; name: string; sites: { id: number | string; name: string; isTimeSave: boolean }[] }[]} */
    const siteCompanyList = await ajax.get(`${BASE_URL}/site-company-list`);
    return siteCompanyList;
}

/**
 * @param {number | string} siteId 
 * @param {{ id: number | string; name: string; sites: { id:number | string; name: string; }[] }[]} companies
 * @returns {Promise<FilterOptions>}
*/
async function getFilterOptions(siteId, companies) {
    const filterOptions = await ajax.get(`${BASE_URL}/options/${siteId}`);
    filterOptions.timeType = ['custom_time', 'last_week', 'last_month', 'last_day', 'last_quarter']
    filterOptions.viewBy = [
        { label: "view_by_years", value: "year" },
        { label: "view_by_months", value: "month" },
        { label: "view_by_weeks", value: "week" },
        { label: "view_by_days", value: "day" }
      ];
    filterOptions.companies = companies;
    filterOptions.servikName = []
    return filterOptions
}

async function getCompanyGeneralData(filter) {
    filter.language = localeData.language
    const res = await ajax.get('company/general', null, filter)
    return res
}

async function getCompaniesAndSites() {
    try {
        const data = await ajax.get('company/general')
        const sitesCompanyMap = data.reduce((acc, item) => {
            const { company_id, company_name, site_id, site_name } = item
            if (!acc[company_id]) acc[company_id] = {
                company_id,
                company_name,
                sites: []
            }
            acc[company_id].sites.push({ site_id, site_name })
            return acc
        }, {})

        return Object.values(sitesCompanyMap)
    } catch (err) {
        return [];
    }

}

/**
* @param {{
* companyId:String | Number;
* siteId?:String | Number;
* floor?:String;
* zone?:String;
* building?: String;
* gender?:String;
* }} filter
*/
async function getRoomListWithRoomDetails(filter) {
    /**
    * @type {{
    * companyId:String | Number;
    * siteId?:String | Number;
    * floor?:String[];
    * zone?:String[];
    * building?: String[];
    * gender?:String[];
    * }}
    */
    const query = {};
    const { building, floor, gender, zone, companyId, siteId } = filter;
    if (building) query.building = JSON.parse(building);
    if (floor) query.floor = JSON.parse(floor);
    if (gender) query.gender = JSON.parse(gender);
    if (zone) query.zone = JSON.parse(zone);
    if (siteId) query.siteId = siteId;
    query.companyId = companyId;
    const rooms = await ajax.get('room/staticRooms', null, query);
    return rooms;
}