import util from '../services/utilService';

export class SelectedFilter {
    /**@type {number | string} */company; //array of company ids
    /**@type {number | string} */site; //array of site ids
    /**@type {TimeType} */timeType;
    /**@type {string} */building = null;
    /**@type {string} */servikName = null;
    /**@type {number} */hour = null;
    /**@type {string[]} */floor = null;
    /**@type {string[]} */gender = null;
    /**@type {number[]} */shift = null;
    /**@type {number[] | string[]} */days = null;
    /**@type {number[] | string[]} */rooms = null; //array of rooms ids
    /**@type {{ timeFrom: string; timeTo: string; }} */time;
    /**@type {TimeBy} */viewTimeBy;
    /**
     * @param {number | string} companyId
     * @param {number | string} siteId
     */
    constructor(companyId, siteId) {
        this.company = companyId;
        this.site = siteId;
        this.timeType = 'last_month';
        this.time = util.getTimeByType(this.timeType);
        this.viewTimeBy = 'day';
    }
}

/**
 * @typedef {import('../services/filterService').TimeType} TimeType
 * @typedef {'day' | 'week' | 'month' | 'year'} TimeBy
 * @typedef {{ id: number; name:string; }} FilterOption
 * @typedef {'year' | 'month' | 'week' | 'day'} ViewTimeByValues
 * @typedef {'view_by_years' | 'view_by_months' | 'view_by_weeks' | 'view_by_days'} ViewTimeByLabels
 *
 * @typedef {{
    * timeType: TimeType[];
    * building?: string[]; //single choice
    * floor?: string[];  //multiselect, disabled while filterSelected.building is null
    * gender?: string[]; //multiselect
    * shift?: FilterOption[]; //multiselect
    * days?: number[] | string[]; //multiselect
    * rooms: FilterOption[]; //allways filled. affected by building, floor, gender, working area
    * viewBy: { label: ViewTimeByLabels, value: ViewTimeByValues }[]
    * }} SiteFilterOptions
    *
    * @typedef {{
    * timeType: TimeType[];
    * building?: string[]; //single choice
    * floor?: string[];  //multiselect, disabled while filterSelected.building is null
    * gender?: string[]; //multiselect
    * shift?: FilterOption[]; //multiselect
    * days?: number[] | string[]; //multiselect
    * rooms: { id: number; name:string; building: string; floor: string; }[]; //allways filled. affected by building, floor, gender, working area
    * viewBy: { label: ViewTimeByLabels, value: ViewTimeByValues }[]
    * companies: { id: number; name: string; sites: { id:number; name: string; isTimeSave: boolean }[] }[]
    * }} FilterOptions
    *
    * @typedef {{
    * company: number | string;
    * site: number | string;
    * timeType: TimeType;
    * servikName?: string;
    * building?: string;
    * floor?: string[];
    * gender?: string[];
    * shift?: number[];
    * days?: number[] | string[];
    * rooms?: number[] | string[];
    * viewTimeBy: string;
    * time: {
    *      timeFrom?: string;
    *      timeTo?:string;
    *  }
    * }} FilterSelected
   */
