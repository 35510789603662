<template>
  <header class="header">
    <div class="logo-container">
      <img :src="require('@/assets/buzzztv_logo.png')" />
    </div>
    <div class="filter-container">
      <CompanySiteFilter
        v-if="$store.getters.filterOptions && !isAdminRoute"
        @input="handleInput"
      />
      <transition name="fade">
        <TimeFilter v-if="isFilterEnabled" @input="handleInput" />
      </transition>
      <div
        class="lang-filter-btn-container"
        :class="{ 'top-right': isAdminRoute }"
        :style="{ 'margin-inline-end': isFilterEnabled ? '' : '20px' }"
      >
        <Select v-model="selectedLanguage" class="lang-select">
          <Option
            v-for="lang in languages"
            :key="lang.value"
            :label="lang.label"
            :value="lang.value"
            :src="require(`@/assets/languages_flags/${lang.value}.svg`)"
          />
        </Select>

        <transition name="fade">
          <div
            class="filter-button"
            :class="{ 'active-filter': isActiveFilter }"
            v-if="isFilterEnabled"
            @click="toggleAdditionalFilter"
          >
            <img :src="require('@/assets/filter.svg')" alt="filter button" />
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import Select from "../../components/Select";
import Option from "../../components/Option";
import TimeFilter from './TimeFilter';
import { localeData } from "../../services/localeService";
import CompanySiteFilter from './CompanySiteFilter';
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  data: () => ({
    selectedLanguage: localeData.language,
    languages: [
      { value: "he", label: "עברית" },
      { value: "en", label: "English" },
      { value: "pr", label: "Português" },
      { value: "ru", label: "Русский" },
      { value: "es", label: "Español" },
      { value: "ar", label: "العربية" },
      { value: "pl", label: "Polish" },
      { value: "uk", label: "Ukrainian" },
      { value: "ro", label: "Romanian" },
    ],
    logoUrl: '',
    //select value changes activates @input event
    shouldUpdate: true,
  }),
  methods: {
    ...mapActions(['getOverviewData', 'getAnalyticsData', 'loadUpdatedOptions']),
    ...mapMutations(['updateFilter']),
    async handleInput(field, value) {
      const { roomId } = this.$route.params;
      if (!this.shouldUpdate) return;
      this.shouldUpdate = false;
      this.updateFilter({ field, value });
      setTimeout(() => this.shouldUpdate = true, 0);
      this.$store.commit({ type: 'toggleLoading', value: true });
      await Promise.all([
        this.getOverviewData({ filter: { ...this.filterSelected, roomId: roomId ? roomId : '' } }),
        this.getAnalyticsData({ filter: { ...this.filterSelected, roomId: roomId ? roomId : '' } }),
        this.loadUpdatedOptions()
      ])
      this.$store.commit({ type: 'toggleLoading', value: false });
    },
    toggleAdditionalFilter() {
      if (!this.isFilterDisabled) return
      this.$store.commit("toggleAdditionalFilter", {});
    },
    toggleLang(language) {
      this.$store.dispatch({ type: "updateLocale", language });
      this.$store.dispatch('loadFilterOptionsAndSelection')
      this.$root.$i18n.locale = language;
    }
  },
  computed: {
    ...mapGetters(['filterSelected']),
    isFilterAvailable() {
      return this.$route.path.includes('dashboard')
    },
    isFilterDisabled() {
      return true;
    },
    isFilterEnabled() {
      return ['performance', 'analytics', 'roomAnalyticsLog', 'cleanLog', 'maintenanceLog', 'roomAnalytics', 'cleanerArrival','roomExposures', 'connectivity', 'power'].includes(this.$route.name);
    },
    isAdminRoute() {
      return this.$route.path.includes('admin') && !this.$route.path.includes('devices') && !this.$route.path.includes('room')
    },
    isActiveFilter() {
      return this.$store.getters.isActiveFilter
    }
  },
  watch: {
    selectedLanguage(value) {
      this.toggleLang(value);
    },
  },
  components: { Option, Select, TimeFilter, CompanySiteFilter }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.header {
  max-height: 10.9vh;
  min-height: 8.3vh;
  box-shadow: 0px 0px 1px $grey;
  background-color: $white;
  @include main-grid;
  @include screen-size(xs) {
    max-height: 12.5vh;
  }

  p {
    min-width: 4.2vw;
    font-size: $font-size-small;
  }

  .md-field.md-theme-default:after {
    display: none;
  }

  .md-field.md-theme-default:before {
    display: none;
  }

  .logo-container {
    grid-column: 1/3;
    display: flex;
    align-items: center;
    margin-inline-start: 25px;
    margin-inline-end: 25px;
    margin-block-start: 10px;
    margin-block-end: 10px;

    @include screen-size(xs) {
      margin-inline-start: 10px;
      margin-inline-end: 10px;
    }

    img {
      max-height: 8.5vh;
      margin: auto;
    }
  }

  .filter-container {
    grid-column: 3/16;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  .filter-button {
    margin-inline-end: 20px;
    height: 4.25vh;
    width: 42px;
    border-radius: 4px;
    cursor: pointer;
    @include flex-center;
    @include border(#e7e7e7);
  }

  .lang-filter-btn-container {
    display: flex;
    align-items: center;
  }
  .active-filter {
    background-color: #e1f2ff;
  }
  .filter-select {
    @include filter-select;
  }

  .lang-select.md-field.select-container {
    width: 115px;
    height: 4.25vh;
    padding: 0;
    margin: 0 3px 0 0;
    font-size: small;
    @include border(#e7e7e7);
    border-radius: 4px;
    .md-menu.md-select .md-input {
      min-width: unset;
    }
  }
  .top-right {
    position: absolute;
    right: 4px;
  }
}
</style>
