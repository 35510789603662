const Surveys = () => import('../views/Surveys');
const SurveyEditPage = () => import('../views/SurveyEditPage');
const SurveyList = () => import('../views/SurveyList');


export const surveysRoutes = [
    {
        path: '/survey',
        component: Surveys,
        children: [
            // WARNING J: before changing any route names or pathes, we made a hardcoded fix on Tabs.vue(with md-tabs)
            // for fixing RTL behaviour
            { name: 'survey', path: '', component: SurveyList },
            { name: 'surveyEdit', path: 'edit/:surveyId?', component: SurveyEditPage },
        ]
    },
]