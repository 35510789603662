import utilService from '../../common/services/utilService';
import { chartColors } from '../consts/consts';

export default { getChartStyles, getOverviewChartData, getTrendlineEq, trendStartEnd };


function getOverviewChartData(overviewData, privateFilter) {
    let chartData = { labels: [], datasets: [] };
    
    chartData.labels = overviewData.visits.map(a => a.time);   //change a.month to a.id
    const filterKeys = Object.keys(privateFilter);
    filterKeys.forEach(key => {
        if (overviewData[key] && privateFilter[key]) {
            const dataset = {}
            dataset.label = key
            let values = overviewData[key].map(a => (key !== 'complaints_vs_visits') ? Math.round(+a.count) : +a.count);
            dataset.data = values
            if (key==='visits') dataset.isAmended = overviewData[key].map(visit=>visit.isAmended ? 'blue':'white')
            chartData.datasets.unshift(dataset);
        } else if (privateFilter[key] && key === 'complaints_vs_visits') {
            let values = _getComplaintsVsVisitsDataset(overviewData.complaints, overviewData.visits);
            chartData.datasets.unshift({ label: key, data: values });
        }
    });
    return chartData;
}

/**@typedef {{ count: Number; time: Number; }} Item
 * @param {Item[]} complaints @param {Item[]} visits */
function _getComplaintsVsVisitsDataset(complaints, visits) {
    const values = complaints.map((complaints, index) => {
        const complaintsVsVisits = complaints.count / visits[index].count;
        if (isNaN(complaintsVsVisits) || complaintsVsVisits === Infinity) return 0;
        return complaintsVsVisits;
    })
    return values
}


/**@param {Object} chartData * @param {Boolean} isShownTicks */
function getChartStyles(chartData, isShownTicks) {
    const { labels, datasets } = chartData;
    let trendlines = [];
    let yAxesOptions = [];
    let chartTitles = [];

    if (isShownTicks) chartTitles = _getChartTitles(datasets);

    const datasetsWithStyle = datasets.map((dataset, index) => {
        // Make sure score is always the highest graph

        const minMax = _getDatasetMinMax(dataset);
        let trendLineItem = _getTrendLineItem(dataset, labels);
        const datasetId = utilService.generateId();

        dataset.yAxisID = datasetId;
        trendLineItem.yAxisID = datasetId;
        trendLineItem.minMax = minMax;
        if (dataset.label !== 'visits') trendlines.push(trendLineItem);

        if (dataset.label === "avg_score") dataset = _assignBarColorsByScore(dataset);
        else dataset.type = "line";

        yAxesOptions[index] = _getYAxesItem(false, dataset, minMax, index, isShownTicks, datasetId, datasets.length);
        if (dataset.label === "avg_score") return dataset;
        else dataset = _overviewDatasetStyle(dataset)
        return dataset;
    });

    trendlines.forEach(trendlineItem => {
        yAxesOptions.push(_getYAxesItem(true, {}, trendlineItem.minMax));
        const labelWithoutTrendline = trendlineItem.label.replace('trendline_', '');
        chartColors[labelWithoutTrendline] ? trendlineItem.borderColor = chartColors[labelWithoutTrendline].line : '';
        trendlineItem.borderWidth = 1;
        trendlineItem.borderDash = [2, 3];
        delete trendlineItem.minMax;
        datasetsWithStyle.push(trendlineItem);
    })

    return { yAxesOptions, chartTitles, datasetsWithStyle }
}

function _assignBarColorsByScore(dataset) {
    dataset.backgroundColor = [];
    dataset.data.forEach(item => dataset.backgroundColor.push(_statusColor(+item)))
    return dataset;
}

function _getDatasetMinMax(dataset) {
    let minMax = utilService.findMaxAndMin(dataset.data);

    if (dataset.label !== 'avg_score') dataset.type = "line";

    if (dataset.label === "avg_score") {
        minMax.min = 0;
        minMax.max = 100;
    } else if (dataset.label === "est_ROI" || dataset.label === "time_saved") {
        minMax.max = Math.round(minMax.max * 4);
        minMax.min = 0;
    } else if (dataset.label === "visits") {
        minMax.max = Math.round(minMax.max * 1.3);
        minMax.min = Math.round(minMax.min * 0.25);
    } else if (dataset.label === "complaints") {
        minMax.max = Math.round(minMax.max * 1.9);
        minMax.min = 0;
    } else if (dataset.label === "response_time_SLA") {
        minMax.max = Math.round(minMax.max * 1.9);
        minMax.min = Math.round(minMax.min * -20);
    } else if (dataset.label === "complaints_vs_visits") {
        minMax.max = Math.round(minMax.max * 3);
        minMax.min = Math.round(minMax.min * -6.5);
    } else if (dataset.label === "arrivals") {
        minMax.max = Math.round(minMax.max * 3);
        minMax.min = Math.round(minMax.min * -6.5);
    }
    return minMax
}

/**@param {Object} [dataset] @param {{min: Number; max:Number;}} [minMax] 
 * @param {Number} [index] @param {Boolean} [isShownTicks]
 * @param {String} [datasetId] @param {Number} [datasetsLength] 
 * @param {Boolean} isTrendLine*/
function _getYAxesItem(isTrendLine, dataset, minMax, index, isShownTicks, datasetId, datasetsLength) {
    if (isTrendLine) {
        return {
            gridLines: { color: "rgba(0, 0, 0, 0)" },
            position: 'left',
            ticks: { display: false, ...minMax }
        };
    } else {
        return {
            gridLines: { color: "rgba(0, 0, 0, 0)" },
            afterTickToLabelConversion: a => {
                for (var tick in a.ticks) {
                    a.ticks[tick] = utilService.kFormatter(a.ticks[tick]);
                }
            },
            position: index === 1 || datasetsLength === 1 ? "left" : "right",
            id: datasetId,
            ticks: {
                fontColor: chartColors[dataset.label].line,
                fontSize: 12,
                display: isShownTicks,
                ...minMax
            }
        }
    }
}

/**@param {Object} dataset * @param {Array<Number | String>} labels*/
function _getTrendLineItem(dataset, labels) {
    const { data, label } = dataset
    /**@type {{x: Number; y: Number}[]} */
    const trendData = data.map((a, index) => {
        return {
            x: index + 1,
            y: a
        };
    });
    const myFunction = getTrendlineEq(trendData);
    const startValue = myFunction(0);
    const endValue = myFunction(trendData.length);
    return {
        label: `trendline_${label}`,
        data: [
            { x: labels[0], y: startValue },
            { x: labels[labels.length - 1], y: endValue }
        ],
        type: "line",
        fill: false
    };
}

function _overviewDatasetStyle(dataset) {
    const pointRadius = dataset.data.length > 52 ? 4 : 6;
    dataset.pointHoverBackgroundColor = chartColors[dataset.label].line;
    dataset.pointHoverBorderColor = chartColors[dataset.label].line;
    dataset.pointHoverBorderWidth = 3;
    dataset.pointHoverRadius = pointRadius;
    dataset.pointBorderColor = chartColors[dataset.label].line;
    dataset.pointBorderWidth = 3;
    dataset.pointHitRadius = pointRadius;
    dataset.pointRadius = pointRadius;
    dataset.pointBackgroundColor = dataset.isAmended ? dataset.isAmended: "white"
    dataset.fill = false;
    dataset.borderColor = chartColors[dataset.label].line;
    dataset.borderWidth = 3;
    return dataset;
}


/**@typedef {{
 * x: Number;
 * y:Number;
 * }} Point
 * @param {Point[]} data
 * @returns {Function} */
function getTrendlineEq(data) {
    const xySum = data.reduce((acc, item) => {
        const xy = item.x * item.y
        acc += xy
        return acc
    }, 0)
    const xSum = data.reduce((acc, item) => {
        acc += item.x
        return acc
    }, 0)
    const ySum = data.reduce((acc, item) => {
        acc += item.y
        return acc
    }, 0)

    const aTop = (data.length * xySum) - (xSum * ySum)

    const xSquaredSum = data.reduce((acc, item) => {
        const xSquared = item.x * item.x
        acc += xSquared
        return acc
    }, 0)

    const aBottom = (data.length * xSquaredSum) - (xSum * xSum)

    const a = aTop / aBottom
    const bTop = ySum - (a * xSum)
    const b = bTop / data.length

    return function trendline(x) {
        return a * x + b
    }
}

/**@param {Number} score */
function _statusColor(score) {
    if (score === 100) return "#2D8144";
    else if (score > 97) return "#3B8B4D";
    else if (score > 94) return "#499657";
    else if (score > 91) return "#56A263";
    else if (score > 88) return "#64AB6B";
    else if (score > 85) return "#77B46B";
    else if (score > 82) return "#8CBC67";
    else if (score > 79) return "#A4C263";
    else if (score > 76) return "#BAC85D";
    else if (score > 73) return "#D1CD5E";
    else if (score > 70) return "#FAC058";
    else if (score > 67) return "#F8AE52";
    else if (score > 64) return "#F79E4F";
    else if (score > 61) return "#F48F4F";
    else if (score > 58) return "#EE824F";
    else if (score > 55) return "#E7734F";
    else if (score > 52) return "#E1654F";
    else if (score > 49) return "#D6544B";
    else if (score > 46) return "#CC4146";
    else return "#C23046";
}

function _getChartTitles(datasets) {
    return datasets.reduce((acc, dataset) => {
        acc.unshift({
            text: dataset.label,
            color: chartColors[dataset.label].line
        });
        return acc;
    }, [])
}

/**
 * @param {Number[]} nums 
 * @returns {{ start: Number; end: Number; }}
*/
function trendStartEnd(nums) {
    const valuesForTrend = nums.map((a, index) => ({ x: index + 1, y: a }));
    const trendFunc = getTrendlineEq(valuesForTrend);
    const start = trendFunc(0);
    const end = trendFunc(nums.length);
    return { start, end };
}

export class OverviewLineChartsData {
    /**@param {Object<string, { count:Number; time:String; }[]>} data */
    constructor(data) {
        const { complaints, response_time_SLA, time_saved, avg_score } = data;
        this.avg_score = avg_score;
        this.complaints = complaints;
        this.response_time_SLA = response_time_SLA;
        this.time_saved = time_saved;
    }

    /**@param {String} property */
    values(property) {
        return this[property].map(a => a.count !== null && +(a.count).toFixed(0))
    }

    /**
     * @param {String} property 
     * @param {Object<number,string>} [monthMap]
    */
    labels(property, monthMap) {
        if(monthMap) return this[property].map(a => monthMap[a.time]);
        return this[property].map(a => `${a.time < 10 ? "0" + a.time : a.time}${a.year ? `/${a.year.toString().slice(2, 4)}` : ""}`)
    }
}