/**@param {number} value */
export function timePassed(value) {
  if (!value) return "00:00";
  value = Math.floor(value / 1000);
  const hours = Math.floor(value / (60 * 60));
  const mins = Math.floor((value - hours * 60 * 60) / 60);
  const tr = (val) => (val >= 10 ? val : "0" + val);
  return `${tr(hours)}:${tr(mins)}`;
}

export function numberToHour(num) {
  return num >= 10 ? `${num}:00` : `0${num}:00`;
}

/**@param {String | Number | Date} date */
export function ddmmyy(date) {
  const time = new Date(date);
  const isMonthZero = time.getMonth() + 1 < 10;
  const isDayZero = time.getDate() < 10;
  const timeStr = (isDayZero ? "0" : "") + time.getDate() + "/" + ((isMonthZero ? "0" : "") + (time.getMonth() + 1)) + "/" + (time.getFullYear() + "").slice(2, 4);
  return timeStr;
}

/**@param {String | Number | Date} date */
export function ddmm(date) {
  const time = new Date(date);
  const isMonthZero = time.getMonth() + 1 < 10;
  const isDayZero = time.getDate() < 10;
  const timeStr = (isDayZero ? "0" : "") + time.getDate() + "/" + ((isMonthZero ? "0" : "") + (time.getMonth() + 1));
  return timeStr;
}

export function dd(date) {
  const time = new Date(date);
  const isDayZero = time.getDate() < 10;
  const timeStr = (isDayZero ? "0" : "") + time.getDate();
  return timeStr;
}

/**@param {String | Number | Date} date */
export function ddmmyyhhmm(date) {
  const time = new Date(date);
  const isMonthZero = time.getMonth() + 1 < 10;
  const isDayZero = time.getDate() < 10;
  const isHourZero = time.getHours() < 10;
  const timeStr = `${(isDayZero ? "0" : "") + time.getDate()}/${(isMonthZero ? "0" : "") + (time.getMonth() + 1)}/${(time.getFullYear() + "").slice(2, 4)} ${(isHourZero ? "0" : "") + time.getHours()}:${time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()}`;
  return timeStr;
}

/**@param {String} txt */
export function spacedTxt(txt) {
  const spaced = txt
    .replace(/([A-Z])([A-Z])/g, "$1 $2")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/[\s_]+/g, " ");
  return spaced;
}

/**@param {Number} seconds */
export function formatSeconds(seconds) {
  var formattedSeconds = seconds + "s";
  if (seconds >= 60) {
    formattedSeconds = Math.ceil(seconds / 60) + "m";
  }
  return formattedSeconds;
}

/**@param {String} str */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function statusColor(score) {
  if (score === null) return ''
  else if (score === 100) return "backgroundColor: #2D8144";
  else if (score > 97) return "backgroundColor: #3B8B4D";
  else if (score > 94) return "backgroundColor: #499657";
  else if (score > 91) return "backgroundColor: #56A263";
  else if (score > 88) return "backgroundColor: #64AB6B";
  else if (score > 85) return "backgroundColor: #77B46B";
  else if (score > 82) return "backgroundColor: #8CBC67";
  else if (score > 79) return "backgroundColor: #A4C263";
  else if (score > 76) return "backgroundColor: #BAC85D";
  else if (score > 73) return "backgroundColor: #D1CD5E";
  else if (score > 70) return "backgroundColor: #FAC058";
  else if (score > 67) return "backgroundColor: #F8AE52";
  else if (score > 64) return "backgroundColor: #F79E4F";
  else if (score > 61) return "backgroundColor: #F48F4F";
  else if (score > 58) return "backgroundColor: #EE824F";
  else if (score > 55) return "backgroundColor: #E7734F";
  else if (score > 52) return "backgroundColor: #E1654F";
  else if (score > 49) return "backgroundColor: #D6544B";
  else if (score > 46) return "backgroundColor: #CC4146";
  else return "backgroundColor: #CC4146";
}

/**@param {String | Number | Date} date */
export function hhmm(date) {
  return new Date(date).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
}