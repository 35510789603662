<template>
  <md-button :class="[skin === 'info' ? 'info' : '', isOpen ? 'open' : '', skin !== 'info' ? `md-${skin}` : '', raised ? 'md-raised' : '', dense ? 'md-dense' : '', isIcon ? 'md-icon-button' : '', isClean ? 'clean' : '']" @click="emitClick" :disabled="disabled">
    <slot></slot>
  </md-button>
</template>

<script>
export default {
  props: {
    skin: {
      type: String,
      validator: (prop) => prop === 'primary' || 'accent' || 'info',
    },
    disabled: { type: Boolean, default: false },
    raised: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    isIcon: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    isClean: { required: false, type: Boolean },
  },
  methods: {
    emitClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss">
.modal {
  .info {
    background-color: #00afd4 !important;
    color: #fff !important;
  }

  .md-primary,
  .md-accent {
    filter: grayscale(0);
    transition: filter .7s;
  }

  .open {
    filter: grayscale(1);
    color: #fff !important;
    transition: filter .7s;
  }
}
</style>
