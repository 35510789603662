<template>
  <label :key="field" class="field-container">
    {{$t(field)}}
    <Select
      class="filter-select"
      :multiple="isMultiSelect"
      :value="initialValue"
      @input="emitInput"
      :disabled="isDisabled"
      :key="field"
    >
      <Option
        v-for="(item, index) in options"
        :value="item.id ? item.id : item"
        :label="item.name ? item.name : item"
        :key="index"
      />
    </Select>
  </label>
</template>

<script>
import Select from '../Select';
import Option from '../Option';
import { mapGetters } from 'vuex';

export default {
  props: {
    field: { type: String, required: true },
    value: { type: Array | String | Number, required: true }
  },
  data: () => ({
    initialValue: []
  }),
  mounted() {
    if (this.filterSelected && this.filterSelected[this.field]) this.initialValue = this.filterSelected[this.field];
  },
  methods: {
    emitInput(value) {
      this.$emit(this.isMultiSelect ? 'multiSelect' : 'select', value, this.field);
    }
  },
  computed: {
    ...mapGetters(['filterSelected']),
    options() {
      if (this.field === 'days') {
        return this.value.map(a => ({ id: a, name: this.$t(`weekMap[${a}]`) }))
      } else return this.value;
    },
    isMultiSelect() {
      if (this.field === 'building' || this.field === 'servikName') return false;
      else return true;
    },
    isDisabled() {
      if (this.field === 'floor' && !this.filterSelected.building) return true;
      else return false;
    },
  },
  components: { Select, Option }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.field-container {
  display: flex;
  font-size: 13px;
  align-items: center;
  // text-transform: capitalize;
  margin-inline-end: 15px;

  .filter-select {
    @include border($grey-border-color);
    height: 35px;
    width: 115px !important;
    border-radius: 4px;
    padding-top: 0;
    margin: 0 3px;
  }
}
</style>