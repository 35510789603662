<template>
      <img
        src="@/assets/Triangle.svg"
        :style="{ transform: rotation }"
      />
</template>

<script>
export default {
    props: {
        /** @type {import('vue').PropOptions<'down' | 'left' | 'up' | 'right'>} */
        direction: {
            type: String,
            default: 'up'
        }
    },
  computed: {
    rotation() {
        switch(this.direction) {
            case 'down':
                return 'rotate(0deg)'
            case 'left':
                return 'rotate(90deg)'
            case 'up':
                return 'rotate(180deg)'
            case 'right':
                return 'rotate(270deg)'
        }
    },
    
  }
};
</script>