import { getOverviewData, getAnalyticsData, getRoomById } from '../services/performanceService'

export default {
    state: {
        overviewData: null,
        analyticsData: null,
        /**@type {Object<string, { value: number; trendInfo: string; }>} */
        barItemsData: null,
        chartListData: null,
        room: {},
        servikNames: []
    },
    getters: {
        overviewData(state) {
            return state.overviewData
        },
        analyticsData(state) {
            return state.analyticsData
        },
        room(state) {
            return state.room
        },
        chartListData(state) {
            return state.chartListData
        },
        /**@returns {Object<string, { value: number | string; trendInfo: string; }>} */
        barItemsData(state) {
            return state.barItemsData
        },
        servikNames(state) {
            return state.servikNames
        }
    },
    mutations: {
        setOverviewData(state, overviewData) {
            state.overviewData = overviewData
        },
        setAnalyticsData(state, analyticsData) {
            state.analyticsData = analyticsData
        },
        setRoom(state, room) {
            state.room = room
        },
        setBarItemsData(state, barItemsData) {
            state.barItemsData = barItemsData
        },
        setChartListData(state, chartListData) {
            state.chartListData = chartListData
        },
        setServikNames(state, servikNames) {
            state.servikNames = servikNames
        }
    },
    actions: {
        async getOverviewData(context, { filter }) {
            const overviewData = await getOverviewData(filter)
            context.commit('setOverviewData', overviewData)
            return overviewData
        },
        async getAnalyticsData(context, { filter }) {
            const analyticsData = await getAnalyticsData(filter)
            context.commit('setAnalyticsData', analyticsData)
            const { charts: { cleaning: { labels } } } = analyticsData
            context.commit('setServikNames', labels)
            return analyticsData
        },
        async getRoomById(context, { id }) {
            const room = await getRoomById(id)
            context.commit('setRoom', room)
            return room
        },
    }
}