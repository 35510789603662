<template>
  <transition name="slide-fade">
    <button class="top-of-view-button" @click="handleClick">
      <TriangleIcon class="triangle-icon" :direction="isRTL? 'right' : 'left'" />
      {{roomData.display_name}}
    </button>
  </transition>
</template>

<script>
import TriangleIcon from '@/modules/common/components/TriangleIcon'
import { mapGetters } from 'vuex';
export default {
  methods: {
    handleClick() {
      this.$router.push({ name: "analytics" });
    }
  },
  computed: {
    ...mapGetters({ roomData: 'room', isRTL: 'isRTL' }),
  },
  components: {
    TriangleIcon
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.top-of-view-button {
  @include flex-center;
  color:$blue;
  font-weight: $font-weight-bold;
  margin-inline-start:3vw;
  .triangle-icon {
    margin-inline-end: 14px;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>