<template>
  <section class="company-filter" v-if="filterOptions.companies && filterOptions.companies.length">
    <div class="company-logo">
      <img class="logo" v-if="currCompanyLogo" :src="currCompanyLogo" />
    </div>

    <template v-if="isUserLevelBuzzz">
      <div class="select-seperator"></div>
      <Select
        @input="handleFranchiseSelect($event)"
        class="franchise-select"
        :placeholder="$t('franchise')"
        :value="currCompanyFranchise"
      >
        <SearchBar class="franchise-search-bar" v-model="searchBy.franchiseName" />
        <Option class="franchise-option" v-for="franchise in franchiseList" :key="franchise" :label="franchise" :value="franchise" />
      </Select>
    </template>

    <div class="select-seperator"></div>

    <Select
      @input="handleSelect($event, 'company')"
      :value="filterSelected.company"
      class="company-select"
      :placeholder="$t('company')"
      :disabled="filterOptions.companies.length === 1"
    >
      <SearchBar class="company-search-bar" v-model="searchBy.companyName" />
      <Option
        class="company-option"
        v-for="company in companiesToShow"
        :key="company.id"
        :label="company.name"
        :value="company.id"
      />
    </Select>

    <div class="select-seperator"></div>

    <Select
      class="site-select"
      @input="handleSelect($event, 'site')"
      :value="filterSelected.site"
      :placeholder="$t('all')"
      :disabled="companySitesLen === 1"
    >
      <SearchBar class="site-search-bar" v-model="searchBy.siteName" v-if="companySitesLen > 1" />
      <Option class="site-option" v-for="site in sitesToShow" :key="site.id" :label="site.name" :value="site.id" />
    </Select>

    <div class="select-seperator"></div>

    <div class="rooms-cleaners-details">{{filterOptions.rooms.length}} {{$t('rooms')}}</div>
    <RoomBackButton v-if="$route.params.roomId" />
  </section>
</template>

<script>
import Select from "../../components/Select";
import Option from "../../components/Option";
import SearchBar from "../../components/SearchBar";
import RoomBackButton from '../../../performance/components/RoomBackButton';
import { mapGetters } from 'vuex';

export default {
  name:'CompanySiteFilter',
  data() {
    return {
      searchBy: {
        companyName: '',
        siteName: '',
        franchiseName: ''
      },
      currFranchise: '',
    }
  },
  created() {
    this.currFranchise = this.currCompanyFranchise
  },
  methods: {
    handleSelect(value, field) {
      this.clearSearch()
      this.$emit('input', field, value);
    },
    clearSearch(){
      this.searchBy.companyName = ''
      this.searchBy.siteName = ''
      this.searchBy.franchiseName = ''
    },
    handleFranchiseSelect(value) {
      this.currFranchise = value
      const companies = this.filterOptions.companies.filter(company => company.franchiseName === value)
      this.handleSelect(companies[0].id, 'company')
      this.handleSelect(companies[0].sites[0].id, 'site')
    }
  },
  computed: {
    ...mapGetters(['filterSelected', 'filterOptions', 'loggedUser']),
    currCompanyLogo() {
      if (!this.filterSelected.company) return null
      let icon;
      try {
        const logoUrl = this.filterOptions.companies.find(c => c.id === this.filterSelected.company).logo;
        if (logoUrl) icon = logoUrl;
      } catch (e) {
        icon = null
      }
      return icon
    },
    companySitesLen() {
      const { company: companyId } = this.filterSelected;
      const { companies } = this.filterOptions;
      const foundCompany = companies.find(company => company.id === companyId)
      if (foundCompany) return foundCompany.sites.length
      else return 1
    },
    sitesToShow() {
      const { company: companyId } = this.filterSelected;
      const { siteName } = this.searchBy
      const foundCompany = this.filterOptions.companies.find(company => company.id === companyId);
      if (foundCompany) return foundCompany.sites.filter(site => site.name.toLowerCase().includes(siteName.toLowerCase()))
      else return []
    },
    companiesToShow() {
      const { companyName } = this.searchBy
      const { companies } = this.filterOptions
      const filteredCompanies = companies.filter(company => company.name.toLowerCase().includes(companyName.toLowerCase()))
      if (this.isUserLevelBuzzz) {
        return filteredCompanies.filter(company => company.franchiseName === this.currFranchise)
      }
      return filteredCompanies
    },
    franchiseList() {
      const { companies } = this.filterOptions
      let franchiseList = companies.map(company => company.franchiseName)
      if (this.searchBy.franchiseName) {
        franchiseList = franchiseList.filter(franchise => franchise.toLowerCase().includes(this.searchBy.franchiseName.toLowerCase()))
      }
      return [...new Set(franchiseList)]
    },
    currCompanyFranchise() {
      const { company: companyId } = this.filterSelected;
      const { companies } = this.filterOptions;
      const foundCompany = companies.find(company => company.id === companyId)
      if (foundCompany) return foundCompany.franchiseName
      else return ''
    },
    isUserLevelBuzzz() {
      return this.loggedUser.level === 'buzzz'
    }
  },
  components: { Select, Option, RoomBackButton, SearchBar }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.company-filter {
  display: flex;
  align-items: center;
  position: relative;

  .company-logo {
    width: 7vw;
    @include screen-size(xs) {
      width: 10vw;
    }
  }

  .select-seperator {
    height: 32px;
    border-left: 1px solid #ececec;
    margin: 0 20px 0 10px;
  }

  .company-select, .franchise-select, .site-select {
    max-width: 10vw;
    @include screen-size(xs) {
      max-width: 17vw;
    }
  }

  .rooms-cleaners-details {
    letter-spacing: -0.23px;
    width: 100px;
    color: $blue;
    @media (max-width: 600px) {
      display: none;
    }
  }

  .logo {
    max-height: 42px;
    max-width: 115px;
    margin: auto;
  }
}

</style>