<template>
	<md-field class="select-container">
		<md-select
			@md-selected="handleInput($event)"
			:value="value"
			:placeholder="placeholder"
			:disabled="disabled"
			:multiple="multiple"
			:style="selectContainerStyle"
		>
			<slot></slot>
		</md-select>
	</md-field>
</template>

<script>
export default {
	props: {
		placeholder: String,
		disabled: { type: Boolean, default: false },
		value: { default: '', type: [String, Number, Array, Boolean] },
		multiple: { type: Boolean, default: false },
		sideLabelStyle: { default: '' },
		src: { type: String, default: '' }
	},
	methods: {
		handleInput(value) {
			this.$emit('input', value)
		}
	},
	computed: {
		selectContainerStyle() {
			if (this.sideLabelStyle.length) return this.sideLabelStyle
			if (this.src.length) {
				let style = {
					'background-image': `url(${this.src})`,
					'background-size': '20px',
					'background-repeat': 'no-repeat',
				}
				if (this.$store.getters.isRTL) {
					style['background-position'] = 'right 10px'
					style['padding-right'] = '23px'
				} else {
					style['background-position'] = 'left 10px'
					style['padding-left'] = '23px'
				}
				style['background-position-y'] = 'center'
				return style
			}
			return ''
		}
	}
}
</script>
