const Dashboard = () => import('../views/Dashboard');

export const overviewRoutes = [
    {
        path: '/dashboard',
        component: Dashboard,
        children: [
            { name: 'overview', path: '/' },
            { name: 'performance', path: 'performance' },
            { name: 'analytics', path: 'analytics', children: [
                {name: 'detailed', path: 'detailed'}, {name: 'analyses', path: 'analyses'}
            ]},
            { name: 'serviceLog', path: 'serviceLog', children: [
                {name: 'cleanLog', path: 'cleanLog/:roomId'}, {name: 'maintenanceLog', path: 'maintenanceLog/:roomId'}
            ]},
            { name: 'roomAnalytics', path: 'roomAnalytics/:roomId' },
            { name: 'cleanerArrival', path: 'cleanerArrival/:roomId' },
            { name: 'roomAnalyticsLog', path: 'roomAnalyticsLog/:roomId' },
            { name: 'roomExposures', path: 'roomExposures/:roomId' },
            { name: 'suggestion', path: 'suggestion'},
        ]
    },
]