import authService from '../services/authService'
import { sessionStore } from '../../common/services/storageService'
export default {
    state: {
        loggedUser: null,
        authError: ''
    },
    getters: {
        loggedUser(state) {
            return state.loggedUser;
        },
        authErrorMsg(state) {
            return state.authError
        }
    },
    mutations: {
        setLoggedUser(state, { user }) {
            state.loggedUser = user;
            const { email } = user
            sessionStore('loggedUser', { email })
        },
        setAuthError(state, { error }) {
            state.authError = error;
        },
    },
    actions: {
        async login(context, {credentials}) {
            try {
                const user = await authService.login(credentials)
                context.commit({type:'setAuthError', error: ''})
                context.commit({type: 'setLoggedUser', user})
                return user
            } catch(err) {
                context.commit({type:'setAuthError', error: err.message})
            }
        },
        async getLoggedInUser(context) {
            try {
                const user = await authService.getLoggedInUser();
                context.commit({type: 'setLoggedUser', user})
                return user;
            } catch(err) {
                //handle UI error
            }
        }
    }
}