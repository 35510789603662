import Vue from 'vue';
import Vuex from 'vuex';
import filter from './modules/common/store/filterStore';
import performance from './modules/performance/store/performanceStore';
import devices from './modules/devices/store/deviceStore';
import auth from './modules/auth/store/authStore';
import { localeData, updateLocaleData } from './modules/common/services/localeService'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isRTL: false,
    isBlankMode: false,
    isScreenVisible: false,
    isLoading: false,
  },
  getters: {
    isRTL(state) {
      return state.isRTL
    },
    isBlankMode(state) {
      return state.isBlankMode
    },
    isScreenVisible(state) {
      return state.isScreenVisible
    },
    isLoading(state) {
      return state.isLoading;
    }
  },
  mutations: {
    toggleBlankMode(state, { value }) {
      state.isBlankMode = value
    },
    setIsRTL(state, { isRTL = false }) {
      state.isRTL = isRTL
    },
    setIsScreenVisible(state, { isVisible }) {
      state.isScreenVisible = isVisible
    },
    toggleLoading(state, { value }) {
      state.isLoading = value;
    }
  },
  actions: {
    updateLocale({ commit }, { language }) {
      if (language) updateLocaleData(language)
      const { isRTL } = localeData
      commit({ type: 'setIsRTL', isRTL })
    },
    setScreenMode({ commit }, { isVisible }) {
      commit({ type: 'setIsScreenVisible', isVisible })
    },
  },
  modules: {
    filter,
    performance,
    auth,
    devices
  }
})
