import { sessionStore, sessionLoad } from '../services/storageService';
import util from "../services/utilService";
import { SelectedFilter } from '../models/Filter';
import filterService from '../services/filterService';

/**
 * @typedef {import('../models/Filter').FilterOptions} FilterOptions 
 * @typedef {import('../models/Filter').FilterSelected} FilterSelected 
 * @typedef {'company' | 'site' | 'timeType' | 'building' | 'floor' | 'gender' | 'shifts' | 'days' | 'rooms' | 'time' | 'viewTimeBy' | 'servikName' | 'hour'} FilterSelectedFields
 * @typedef {{
 * filterOptions: FilterOptions | null;
 * filterSelected: FilterSelected | null;
 * isAdditionalFilterOpen: boolean;
 * isToggleFilterDisabled: boolean;
 * }} State
*/

export default {
    /**@type {State} */
    state: {
        filterOptions: null,
        filterSelected: null,
        isAdditionalFilterOpen: false,
        isToggleFilterDisabled: false,
        isActiveFilter: false,
        additionalActiveFilter: {
            rooms: true,
            building: true,
            floor: true,
            gender: true,
            shift: true,
            days: true,
            timeType:true,
            servikName: true
        }
    },
    getters: {
        /**@param {State} state */
        currentFilteringSite(state) {
            const company = state.filterOptions.companies.find(company => {
                return state.filterSelected.company === company.id;
            })
            const { sites } = company;
            return sites.find(site => site.id === state.filterSelected.site);
        },
        /**@param {State} state */
        roomIdMap(state) {
            const { rooms } = state.filterOptions;
            return rooms.reduce((roomMap, room) => {
                roomMap[room.id] = room;
                return roomMap;
            }, {});
        },
        filterSelected(state) {
            return state.filterSelected;
        },
        additionalActiveFilter(state) {
            return state.additionalActiveFilter;
        },
        isActiveFilter(state) {
            return state.isActiveFilter;
        },
        filterOptions(state) {
            if (!state.filterOptions) return null;
            /**@type {FilterOptions} */
            let filterOptionsCopy = JSON.parse(JSON.stringify(state.filterOptions));
            filterOptionsCopy.rooms = filterOptionsCopy.rooms.filter(room => room.is_calculated === 1)
            filterOptionsCopy = {
                building: filterOptionsCopy.building,
                floor: filterOptionsCopy.floor,
                gender: filterOptionsCopy.gender,
                rooms: filterOptionsCopy.rooms,
                ...filterOptionsCopy
            }
            if (state.filterSelected) {
                /**@type {FilterSelected}*/
                const { timeType } = state.filterSelected;
                // get relevant viewBy options for last quarter & last year
                if (timeType === 'last_quarter' || timeType === 'last_year') {
                    filterOptionsCopy.viewBy = filterOptionsCopy.viewBy.filter(type => type.value !== 'day');
                    return filterOptionsCopy;
                }
                const { building: currBuilding, floor: currFloors } = state.filterSelected
                if (currBuilding) {
                    filterOptionsCopy.floor = Object.keys(filterOptionsCopy.building[currBuilding])
                    if (currFloors) filterOptionsCopy.rooms = filterOptionsCopy.rooms.filter(room => currFloors.includes(room.floor)); // get relevant floors for current floors
                    else filterOptionsCopy.rooms = filterOptionsCopy.rooms.filter(room => currBuilding === room.building); // get relevant floors for current building
                }
            }
            filterOptionsCopy.building = Object.keys(filterOptionsCopy.building);
            return filterOptionsCopy;
        },
        isAdditionalFilterOpen(state) {
            return state.isAdditionalFilterOpen;
        },
        isToggleFilterDisabled(state) {
            return state.isToggleFilterDisabled;
        },
    },
    mutations: {
        setFilterSelected(state, { filterSelected }) {
            state.filterSelected = filterSelected;
            sessionStore('filterSelected', filterSelected);
        },
        setFilterOptions(state, { filterOptions }) {
            state.filterOptions = filterOptions;
        },
        /**
         * @param {State} state
         * @param {{ 
         * field: FilterSelectedFields;
         * value: any;
         * }} param2
        */
        updateFilter(state, { field, value }) {
            if (!state.filterSelected) return;
            switch (field) {
                case 'timeType':
                    if (value !== 'custom_time') {
                        state.filterSelected.time = util.getTimeByType(value);
                        if (value === 'last_quarter' || value === 'last_year') state.filterSelected.viewTimeBy = 'week';
                        else state.filterSelected.viewTimeBy = 'day';

                    }
                    if (!value) {
                        value = 'last_month'
                    }
                    break;
                case 'building':
                    state.filterSelected.rooms = null;
                    state.filterSelected.floor = null;
                    break;
                case 'floor':
                    state.filterSelected.rooms = null;
                    break;
                case 'company':
                    const firstSiteId = state.filterOptions.companies.find(c => c.id === value).sites[0].id;
                    state.filterSelected = new SelectedFilter(value, firstSiteId);
                    break;
                case 'site':
                    state.filterSelected = new SelectedFilter(state.filterSelected.company, value);
                    break;
                case 'servikName':
                    if (state.filterSelected[field] === value) value = null;
                    break;
                case 'hour':
                    if (state.filterSelected[field] === value) value = null;
                    break;
                case 'rooms':
                    state.filterSelected.rooms = value;
                    break;
            }

            state.filterSelected[field] = value;
            sessionStore('filterSelected', state.filterSelected);
        },
        setToggleFilterDisabled(state, { value }) {
            state.isToggleFilterDisabled = value;
        },
        setFilterStatus(state, { value }) {
            state.isActiveFilter = value;
        },
        toggleAdditionalFilter(state, { value }) {
            if (value || value === false) state.isAdditionalFilterOpen = value;
            else state.isAdditionalFilterOpen = !state.isAdditionalFilterOpen;
        },
        setFilterServikNames(state, { servikNames }) {
            state.filterOptions.servikName = servikNames || []
        }
    },
    actions: {
        async loadFilterOptionsAndSelection({ commit }) {
            const user = sessionLoad('loggedUser')
            if (!user) return
            const companiesWithSites = await filterService.getSiteCompanyList();
            
            let firstCompanyId = null;
            let firstSiteId = null;
            if (companiesWithSites.length) {
                firstCompanyId = companiesWithSites[0].id;
                firstSiteId = companiesWithSites[0].sites[0].id;
            }

            /**@type {SelectedFilter} */
            const filterSelected = sessionLoad("filterSelected") || new SelectedFilter(firstCompanyId, firstSiteId);
            const filterOptions = await filterService.getFilterOptions(filterSelected.site, companiesWithSites);
            commit({ type: 'setFilterOptions', filterOptions });

            commit({ type: 'setFilterSelected', filterSelected });
            return filterSelected
        },
        async loadUpdatedOptions({ commit, state }) {
            const companiesWithSites = await filterService.getSiteCompanyList()
            const filterOptions = await filterService.getFilterOptions(state.filterSelected.site, companiesWithSites)
            commit({ type: 'setFilterOptions', filterOptions })
            return filterOptions;
        }
    },
}