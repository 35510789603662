import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMaterial from 'vue-material';
import vuetify from '@/plugins/vuetify';
import VueI18n from 'vue-i18n';
import { he, en,pr,ru,es,ar, pl, uk, ro } from './texts';
import { localeData, updateLocaleData } from './modules/common/services/localeService'
import { timePassed, numberToHour, ddmmyy, ddmm, dd, spacedTxt, formatSeconds, capitalize, statusColor, hhmm } from '@/filters'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.config.productionTip = false;

Vue.use(VueMaterial);

Vue.use(VueI18n);
updateLocaleData()
const { language } = localeData
const messages = { en, he,pr,ru,es,ar,pl,uk, ro };
const i18nData = { locale: language, messages, fallbackLocale: 'en' }
const i18n = new VueI18n(i18nData);

Vue.filter('timePassed', timePassed)
Vue.filter('numberToHour', numberToHour)
Vue.filter('ddmmyy', ddmmyy)
Vue.filter('ddmm', ddmm)
Vue.filter('dd', dd)
Vue.filter('spacedTxt', spacedTxt)
Vue.filter('formatSeconds', formatSeconds)
Vue.filter('capitalize', capitalize)
Vue.filter('statusColor', statusColor)
Vue.filter('hhmm', hhmm)

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    window.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', window.event)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', window.event)
  },
});




new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
