import ajax from '../../common/services/httpService';
import { ddmmyyhhmm } from '../../../filters';
import swalService from "../../common/services/swalService";
import utilService from '../../common/services/utilService';

const SENSOR_URL = 'sensor';
const KIOSK_URL = 'kiosk'
const DEVICE_OK_TIME = 1;
const PAD_OK_TIME = 1;
const DEVICE_WARNING_TIME = 3
const OK_BLUE_ICON = 'OK_blue'
const OK_GREEN_ICON = 'OK'
const WARNING_BLUE_ICON = 'warning_blue'
const PAD_WARNING_BLUE_ICON = 'pad_warning_blue'
const WARNING_ORANGE_ICON = 'warning_orange'
const WARNING_RED_ICON = 'warning_red'
const OFFLINE_ICON = 'offline'

/**
 * @param {number} siteId
 * @returns {Promise<{
 * battery: { battery: number; };
 * date: { date: Date; text: string; };
 * device_id: string;
 * device_status: { level: string };
 * device_type: string;
 * id: string | number;
 * installation_spot?: string;
 * none: {
 *      icon: string;
 *      id: string;
 *      tooltip: { text: string; }
 *      type: string;
 *  }[];
 * room_name: string;
 * status: {
 *      deviceCode: string;
 *      icon: string;
 *      tooltip: { text: string; }
 *  };
 * }[]>}
*/
export async function getDevices(siteId) {
    if (!siteId) return
    try {
        let devices = await ajax.get(`${SENSOR_URL}/status`, null, { siteId })
        if (!devices && !devices.length) return [];
        devices = devices.map((currDevice, idx) => {
            const { device_type, device_id, version } = currDevice;
            const isPad = device_type === 'pad9' || device_type === 'pad9+'
            const isTablet = device_type === 'tablet'
            const isCounter = device_type.includes('counter')
            if (!currDevice.last_signal) currDevice.last_signal = '0000'
            if (typeof currDevice.note === 'string') currDevice.note = JSON.parse(currDevice.note)
            let lastSignalDate = new Date(currDevice.last_signal);
            if (new Date().getTime() < lastSignalDate.getTime()) lastSignalDate = new Date();
            if (!currDevice.note) {
                currDevice.note = {
                    txt: '',
                    date: ddmmyyhhmm(currDevice.last_signal)
                }
            }
            currDevice.status = {
                icon: _getStatusIcon(lastSignalDate, currDevice, isPad),
                tooltip: currDevice.note.txt ? { text: currDevice.note.txt, boldText: currDevice.note.date } : null,
                deviceCode: currDevice.device_id
            }
            currDevice.counter_status = {
                icon: device_type === 'pad9+' ? _getPadCounterStatusIcon(currDevice.counter_state, lastSignalDate) : '',
            }
            currDevice.actions = []
            if (isTablet && !version) {
                const appType = 'kiosk'
                currDevice.actions = [
                    { icon: "devices/cloud_download", type: 'profileDownload', tooltip: { text: 'Force Profile Download' }, id: currDevice.device_id, appType },
                    { icon: "devices/image_icon", type: 'screenshot', tooltip: { text: 'Request Application Screenshot' }, id: currDevice.device_id, appType },
                    { icon: "devices/team_viewer", type: 'teamViewer', tooltip: { text: 'Launch TeamViewer QuickSupport on Device' }, id: currDevice.device_id, appType },
                    { icon: "devices/phone_arrow", type: 'restart', tooltip: { text: 'Restart Application on Device(s)' }, id: currDevice.device_id, appType },
                    { icon: "devices/device_settings", type: 'openKiosk', tooltip: { text: 'Open Kiosk Browser Settings on Device' }, id: currDevice.device_id, appType },
                ]
            } else if (isPad || isCounter) {
                const { user_flags, sensor_flags, is_update, is_restart, device_id } = currDevice;
                currDevice.actions = { id: device_id, is_update, is_restart, user_flags, sensor_flags }
            } else if (isTablet && version) {
                // If there is version that means the native survey app is installed on the tablet
                const { device_id: id } = currDevice;
                const appType = 'native'
                currDevice.actions = [
                    { id, icon: "devices/cloud_download", type: 'refresh', tooltip: { text: 'Refresh Survey' }, appType },
                    { id, icon: "devices/phone_arrow", type: 'restart', tooltip: { text: 'Restart Application' }, appType },
                ]
            }

            currDevice.id = idx
            //to be able to sort table
            currDevice.date = {
                date: new Date(currDevice.last_signal),
                text: ddmmyyhhmm(currDevice.last_signal)
            }
            currDevice.device_type = currDevice.device_type.charAt(0).toUpperCase() + currDevice.device_type.slice(1)

            currDevice.version = {
                text: currDevice.version,
                icon: "devices/device_info",
                tooltip: { texts: [
                    `SSID: ${currDevice.SSID ? currDevice.SSID : 'N/A'}`,
                    `RSSI: ${currDevice.RSSI ? currDevice.RSSI : 'N/A'}`,
                    `SimID: ${currDevice.SimID ? currDevice.SimID : 'N/A'}`,
                ],
                isCapitalized: false
                }
            }

            currDevice.latest_errors = {
                txt: '',
                icon: "devices/errors_list",
                deviceCode: device_id,
            }
            
            const { sensor_code, 
                survey_id,
                device_code,
                last_signal,
                note,
                SSID,
                RSSI,
                SimID,
                user_flags, 
                sensor_flags, 
                is_update, 
                is_restart, 
                state, 
                device_status,
                counter_state,
                ...updatedDevice } = currDevice;

            return updatedDevice;
        });
        return devices
    } catch (err) {
        swalService.errorMsg()
        return []
    }
}

/**
 * 
 * @param {*} siteId 
 * @param {*} dates
 * @returns 
 */
export async function getDevicesConnectivity(siteId, dates) {
    if (!siteId || !dates) return
    try {
        const devicesConnectivity = await ajax.get(`${SENSOR_URL}/connectivity`, null, { siteId, dates })
        return devicesConnectivity
    } catch (err) {
        swalService.errorMsg()
        return {}
    }
}

/**
 * 
 * @param {*} siteId 
 * @param {*} dates
 * @returns 
 */
export async function getDevicesBatteryPower(filter) {
    try {
        const devicesBatteryPower = await ajax.get(`${SENSOR_URL}/battery/power`, null, filter)
        return devicesBatteryPower
    } catch (err) {
        swalService.errorMsg()
        return {}
    }
}

/**
 * @param {number} siteId
*/
export async function getAllDevices(siteId) {
    if (!siteId) return
    try {
        let devices = await ajax.get(`${SENSOR_URL}`, null, { site_id:siteId })
        return devices
    } catch (err) {
        swalService.errorMsg()
        return []
    }
}

/** @param {String} id */
export async function getDeviceScreenshot(id) {
    try {
        const screenshot = await ajax.get(`${KIOSK_URL}/screenshot/${id}`)
        if (!screenshot || typeof screenshot !== 'string') {
            throw new Error('did not get image')
        }
        window.open(screenshot)
    } catch (err) {
        swalService.errorMsg('Device might be unavailable')
        return
    }
}

/** @param {String} id */
export async function profileDownload(id) {
    try {
        const profile = await ajax.get(`${KIOSK_URL}/profileDownload/${id}`)
        if (!profile) {
            swalService.errorMsg()
            return
        } else {
            swalService.savedMsg('profile Downloaded')
        }
    } catch (err) {
        swalService.errorMsg()
        return
    }
}

/** @param {String} id */
export async function openKioskBrowser(id) {
    try {
        const res = await ajax.get(`${KIOSK_URL}/openKioskBrowserSettings/${id}`)
        if (!res) {
            swalService.errorMsg()
            return
        } else {
            swalService.savedMsg('Kiosk Browser Opened')
            return
        }
    } catch (err) {
        swalService.errorMsg()
        return
    }
}

/** @param {String} id */
export async function openTeamViewer(id) {
    try {
        const res = await ajax.get(`${KIOSK_URL}/openTeamViewerQuickSupport/${id}`)
        if (!res) {
            swalService.errorMsg()
            return
        } else {
            swalService.savedMsg('Team Viewer Opened')
            return
        }
    } catch (err) {
        swalService.errorMsg()
        return
    }
}

/** @param {String} id */
export async function restartKiosk(id) {
    try {
        const res = await ajax.get(`${KIOSK_URL}/restartApplication/${id}`)
        if (!res) {
            swalService.errorMsg()
            return
        } else {
            swalService.savedMsg('Kiosk App Restarted')
            return
        }
    } catch (err) {
        swalService.errorMsg()
        return
    }
}

export async function refreshTabletSurvey(deviceCode) {
    try {
        const res = await ajax.post(`${SENSOR_URL}/tabletAction`, { deviceCode, action: 'refresh' })
        if (res.success) {
            swalService.savedMsg('Tablet Native App Refreshed')
            return
        } else {
            swalService.errorMsg()
            return
        }
    } catch (err) {
        swalService.errorMsg()
        return
    }
}

export async function restartTabletApplication(deviceCode) {
    try {
        const res = await ajax.post(`${SENSOR_URL}/tabletAction`, { deviceCode, action: 'restart' })
        if (res.success) {
            swalService.savedMsg('Tablet Native App Restarted')
            return
        } else {
            swalService.errorMsg()
            return
        }
    } catch (err) {
        swalService.errorMsg()
        return
    }
}

/**@param {String} action
 * @param {String} id */
export async function tabletAction(action, id, appType) {
    if (!action || !appType) return
    if (appType === 'kiosk') {
        switch (action) {
            case 'screenshot':
                await getDeviceScreenshot(id)
                break;
            case 'profileDownload':
                await profileDownload(id);
                break;
            case 'openKiosk':
                await openKioskBrowser(id);
                break;
            case 'teamViewer':
                await openTeamViewer(id);
                break;
            case 'restart':
                await restartKiosk(id);
                break;
            default:
                return;
        }
    } else if (appType === 'native') {
        switch (action) {
            case 'refresh':
                await refreshTabletSurvey(id);
                break;
            case 'restart':
                await restartTabletApplication(id);
                break;
            default:
                return;
        }
    }
}

/**@param {Number} surveyId */
export async function getDeviceCodeBySurveyId(surveyId) {
    try {
        const deviceCode = await ajax.get(`${SENSOR_URL}/survey`, null, { surveyId })
        return deviceCode
    } catch (err) {
        console.log('ERROR', err)
    }
}

/**@param {Date} date */
function _getStatusIcon(date, device, isPad) {
    const { note, state } = device
    const isNoteTxt = note ? note.txt.length > 0 : false
    const hoursSinceLastSignal = utilService.getHoursPassed(date)
    if (isPad) return _getStatusIconByState({ isNoteTxt, state, hoursPassed: hoursSinceLastSignal })
    else return _getStatusIconByLastSignalDate({ isNoteTxt, hoursPassed: hoursSinceLastSignal })
}

// TODO: after we have the last_flags_request feature on the devices
// return the status only by the counterState, no need for the date anymore
function _getPadCounterStatusIcon(counterState, date) {
    if (!counterState || counterState === 'offline') return OFFLINE_ICON
    // if (counterState === 'online') return OK_GREEN_ICON
    const hoursSinceLastSignal = utilService.getHoursPassed(date)
    const status = _getStatusIconByLastSignalDate({ hoursPassed: hoursSinceLastSignal })
    if (status === WARNING_RED_ICON) return OFFLINE_ICON
    return status
}

/**@param {Boolean} isNoteTxt */
/**@param {String} state */
/**@param {Number} hoursPassed */
function _getStatusIconByState({ isNoteTxt, state, hoursPassed }) { 
    // if (hoursPassed < PAD_OK_TIME || state === 'online') return isNoteTxt ? OK_BLUE_ICON : OK_GREEN_ICON;
    // if (!state || state === 'offline') return isNoteTxt ? PAD_WARNING_BLUE_ICON : OFFLINE_ICON;
    if (hoursPassed <= PAD_OK_TIME) return isNoteTxt ? OK_BLUE_ICON : OK_GREEN_ICON;
    else if (hoursPassed > PAD_OK_TIME) return isNoteTxt ? PAD_WARNING_BLUE_ICON : OFFLINE_ICON;
}

/**@param {Boolean} isNoteTxt */
/**@param {Number} hoursPassed */
function _getStatusIconByLastSignalDate({ isNoteTxt, hoursPassed }) {
    if (hoursPassed < DEVICE_OK_TIME) return isNoteTxt ? OK_BLUE_ICON : OK_GREEN_ICON;
    else if (hoursPassed < DEVICE_WARNING_TIME) return isNoteTxt ? WARNING_BLUE_ICON : WARNING_ORANGE_ICON;
    else if (hoursPassed > DEVICE_WARNING_TIME) return isNoteTxt ? WARNING_BLUE_ICON : WARNING_RED_ICON;
    else return isNoteTxt ? WARNING_BLUE_ICON : WARNING_RED_ICON;
}