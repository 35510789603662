import ajax from '../../common/services/httpService';
const BASE_URL = 'auth'

/**
 * @param {Object} userCred
 * @param {string} userCred.loginInput
 * @param {string} userCred.password
 */
async function login(userCred) {
    try {
        const user = await ajax.post(`${BASE_URL}/login`, userCred)
        return user
    } catch (err) {
        if (err && err.status === 401) {
            throw new Error('Wrong email or password')
        } else {
            throw new Error('Could not log in')
        }
    }
}

async function getLoggedInUser() {
    try {
        const user = await ajax.get(`${BASE_URL}/loggedInUser`);
        return user
    } catch (err) {
        throw new Error('Could not get user');
    }
}

async function logout() {
    try {
        await ajax.post(`${BASE_URL}/logout`)
        sessionStorage.clear()
        return true
    } catch (err) {
        throw new Error('Could not log out');
    }
}

/**@param {string} email */
async function isEmailValid(email) {
    try {
        const isValid = await ajax.get(`${BASE_URL}/is-email-valid`, null, { email });
        return isValid;
    } catch (err) {
        throw new Error('server had problem testing email');
    }
}

async function isUsernameValid(username) {
    try {
        const isValid = await ajax.get(`${BASE_URL}/is-username-valid`, null, { username });
        return isValid;
    } catch (err) {
        throw new Error('server had problem testing username');
    }
}

async function isPhoneValid(phone) {
    try {
        const isValid = await ajax.get(`${BASE_URL}/is-phone-valid`, null, { phone });
        return isValid;
    } catch (err) {
        throw new Error('server had problem testing phone');
    }
}

export default {
    login,
    isEmailValid,
    getLoggedInUser,
    logout,
    isUsernameValid,
    isPhoneValid
}

