const Admin = () => import('../views/Admin')
const Company = () => import('../views/Company.vue')
const Room = () => import('../views/Room')
const RoomDetails = () => import('../views/RoomDetails')
const User = () => import('../views/User')
const SiteDetails = () => import('../views/SiteDetails')
const Site = () => import('../views/Site')
const Franchise = () => import('../views/Franchise')
const DeviceAlerts = () => import('../views/DeviceAlerts')
const Devices = () => import('@/modules/devices/views/Devices')

export const adminRoutes = [
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        children: [
            { name: 'franchise', path: 'franchise', component: Franchise },
            {
                name: 'company', path: 'company', component: Company, children:
                    [{ name: 'cleaningSP', path: 'cleaning' }, { name: 'managementSP', path: 'management' }]
            },
            { name: 'site', path: 'site', component: Site },
            { name: 'siteDetails', path: 'site/:id', component: SiteDetails },
            {
                name: 'room', path: 'room', component: Room, children:
                    [{ name: 'roomList', path: 'list' }, { name: 'roomGenerator', path: 'generate' }]
            },
            { name: 'roomDetails', path: 'room/:id', component: RoomDetails },
            { name: 'user', path: 'user', component: User },
            {
                name: 'devices', path: 'devices', component: Devices, children:
                    [{ name: 'connectivity', path: 'connectivity' }, { name: 'power', path: 'power' }]
            },
            {
                name: 'device-alerts', path: 'device-alerts', component: DeviceAlerts, children:
                    [{ name: 'device-active', path: 'active' }, { name: 'device-counter', path: 'counter' }, { name: 'device-search', path: 'search' }]
            },
        ]
    },
]