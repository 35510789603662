<template>
	<input type="text" :autocomplete="autocomplete" class="search-bar" :value="value" name="search" :placeholder="$t('search')" @input="onInput" />
</template>

<script>
export default {
	name: 'SearchBar',
	props: {
		value: {
			type: String,
			default: ''
		},
		autocomplete: {
			type: String,
			default: 'off'
		}
	},
	methods: {
		onInput(event) {
			this.$emit('input', event.target.value)
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
.search-bar {
	min-height: 40px;
	border: 0.1px solid var(--md-theme-default-primary-on-background, #448aff);
	padding: 4px 16px;
	font-size: 100%;
	border-radius: 2px;
	position: sticky;
	z-index: 10;
	top: 0;
	background-color: $white
}
</style>
