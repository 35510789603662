const DAYS = {
	1: 'sunday',
	2: 'monday',
	3: 'tuesday',
	4: 'wednesday',
	5: 'thursday',
	6: 'friday',
	7: 'saturday'
}

/**@param {Date} date */
function dateToDD_MM_YYYY(date) {
	let month = `${date.getMonth() + 1}`.padStart(2, '0')
	let day = `${date.getDate()}`.padStart(2, '0')
	let year = date.getFullYear()
	let output = day + '.' + month + '.' + year
	return output
}

/**@param {Array<Array>} masterArray*/
function lengthOfLongestArrayInMatrix(masterArray) {
	const index = masterArray.reduce((p, c, i, a) => (a[p].length > c.length ? p : i), 0)
	return masterArray[index].length
}

export const capitalize = (string) => {
	return string.replace(/^\w/, (c) => c.toUpperCase())
}

function generateId() {
	return (
		Math.random()
			.toString(36)
			.substring(2, 15) +
		Math.random()
			.toString(36)
			.substring(2, 15)
	)
}

function kFormatter(num) {
	return Math.abs(num) > 999
		? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k'
		: Math.sign(num) * Math.abs(num)
}

function kMinutesToHours(num) {
	return Math.abs(num) > 999 ? (Math.abs(num) / 60).toFixed(1) + 'h' : Math.sign(num) * Math.abs(num)
}

/**@param {Object[]} items @param {String} str @param {String} objectKey*/
function filterArrayOfObjectsWithString(items, str, objectKey) {
	if (!str) return items
	return items.filter(
		(item) =>
			item[objectKey] &&
			item[objectKey]
				.toString()
				.toLowerCase()
				.includes(str.toLowerCase())
	)
}

/**@param {Object} object */
function saveToQueryParams(object) {
	/**@type {String} */
	let queryString = ''
	for (var key in object) {
		if (queryString != '') {
			queryString += '&'
		}
		queryString += key + '=' + JSON.stringify(object[key])
	}
	window.location.search = queryString
	return queryString
}

/**@param {'custom_time' | 'last_week' | 'last_month' | 'last_year' | 'last_day' | 'last_quarter'} value
 * @returns {{ timeTo: String, timeFrom: String }} format: YYYY-MM-DD*/
function getTimeByType(value) {
	let filter = { timeTo: '', timeFrom: '' }
	if (value === 'custom_time') return
	const today = new Date(new Date().setDate(new Date().getDate() - 1))
	filter.timeTo = today.toISOString().slice(0, 10)
	let from = null
	if (value === 'last_day') from = new Date(Date.now() - 36 * 100000 * 24 * 1) //1 day ago
	if (value === 'last_week') from = new Date(Date.now() - 36 * 100000 * 24 * 7) //7 days ago
	if (value === 'last_month' || !value) from = new Date(new Date().setMonth(new Date().getMonth() - 1)) //1 Month back from now
	if (value === 'last_quarter') from = new Date(Date.now() - 36 * 100000 * 24 * 31 * 4)
	if (value === 'last_year') from = new Date(Date.now() - 36 * 100000 * 24 * 31 * 12)
	filter.timeFrom = from.toISOString().slice(0, 10)
	return filter
}

function findMaxAndMin(arr) {
	let max = -Infinity
	let min = Infinity
	for (let i = 0; i < arr.length; i++) {
		const item = +arr[i]
		if (item > max) max = item
		if (item < min) min = item
	}
	return {
		min,
		max
	}
}

/**@param {String} url*/
function isValidUrl(url) {
	let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm
	return regEx.test(url)
}

/**@param {String} url*/
function stringInEnglish(string) {
	if (!string) return false
	const english = /^[A-Za-z0-9]*$/
	return english.test(string)
}

/**@param {Number[]} numbers*/
function sortParallelArrays(numbers, strings) {
	const bothArray = [...numbers].map((item, index) => {
		return {
			num: item,
			str: strings[index]
		}
	})
	bothArray.sort((a, b) => b.num - a.num)
	return bothArray
}

/**@param {Date} date */
function getHoursPassed(date) {
	const now = new Date()
	const timeDiff = now.getTime() - date.getTime()
	const hoursPassed = timeDiff / 1000 / 60 / 60
	return hoursPassed
}

function getWeekDaysNames() {
	const weekDaysNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
	return weekDaysNames
}

function getDayHours(minuteSteps = 15) {
	const hours = []
	for (let i = 0; i < 24; i++) {
		for (let j = 0; j < 60; j += minuteSteps) {
			const hour = i < 10 ? '0' + i : i
			const minute = j < 10 ? '0' + j : j
			hours.push(hour + ':' + minute)
		}
	}
	return hours
}

function convertUtcHourToLocalHour(hour) {
	const hourParts = hour.split(':')
	const [utcHour, utcMinute] = hourParts
	const localTime = new Date()
	localTime.setUTCHours(utcHour)
	localTime.setUTCMinutes(utcMinute)
	const localHour = localTime.getHours()
	const localMinute = localTime.getMinutes()
	const localHourStr = localHour < 10 ? '0' + localHour : localHour
	const localMinuteStr = localMinute < 10 ? '0' + localMinute : localMinute
	return `${localHourStr}:${localMinuteStr}`
}

function convertLocalHourToUtcHour(hour) {
	const hourParts = hour.split(':')
	const [localHour, localMinute] = hourParts
	const utcTime = new Date()
	utcTime.setHours(localHour)
	utcTime.setMinutes(localMinute)
	const utcHour = utcTime.getUTCHours()
	const utcMinute = utcTime.getUTCMinutes()
	const utcHourStr = utcHour < 10 ? '0' + utcHour : utcHour
	const utcMinuteStr = utcMinute < 10 ? '0' + utcMinute : utcMinute
	return `${utcHourStr}:${utcMinuteStr}`
}

/**
 * @param {Number} dayNum
 * @returns {String}
 * */
function getWeekDayName(dayNum) {
	if (!dayNum) return
	if (_isDayNumValid(dayNum)) {
		return DAYS[dayNum]
	}
}

function isCharValid(lang, char) {
	if (!lang || !char) return false
	const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9 ]/

	const langsRegex = {
		english: /[a-zA-Z]/,
		hebrew: /[א-ת]/,
		portuguese: /[a-zA-ZçÇáÁàÀãÃâÂéÉèÈêÊíÍìÌîÎóÓòÒõÕôÔúÚùÙûÛ]/,
		russian: /[а-яА-ЯёЁ]/,
		spanish: /[a-zA-ZáÁàÀãÃâÂéÉèÈêÊíÍìÌîÎóÓòÒõÕôÔúÚùÙûÛñÑ]/,
		arabic: /[ء-ي]/,
		amharic: /[አ-ፚ]/,
		polish: /[a-zA-ZąĄćĆęĘłŁńŃóÓśŚźŹżŻ]/,
		ukrainian: /[а-яА-ЯїЇєЄґҐіІ]/
	}

	const isValid = langsRegex[lang].test(char) || specialCharRegex.test(char)
	return isValid
}

/**
 * @param {Number} dayNum
 * @returns {Boolean}
 * */
function _isDayNumValid(dayNum) {
	return dayNum >= 1 && dayNum <= 7
}

function getNumRange(start, end) {
	const range = []
	for (let i = start; i <= end; i++) {
		range.push(i)
	}
	return range
}

function getWeeksRange(start, end) {
	const weeksNames = ['1st week', '2nd week', '3rd week', '4th week', '5th week']
	const range = []
	for (let i = start; i <= end; i++) {
		range.push(weeksNames[i - 1])
	}
	return range
}

function isPastDate(date) {
	date = new Date(date)
	const now = new Date()
	if (date < now) {
		return true
	}
	return false
}

function isValidEmail(email) {
	if (!email) return false
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return emailRegex.test(email)
}

function flattenArray(arr) {
	let result = []

	arr.forEach((element) => {
		if (Array.isArray(element)) {
			// If the element is an array, recursively flatten it
			result = result.concat(flattenArray(element))
		} else {
			// If the element is not an array, add it to the result
			result.push(element)
		}
	})

	return result
}

export default {
	filterArrayOfObjectsWithString,
	generateId,
	sortParallelArrays,
	kFormatter,
	saveToQueryParams,
	getTimeByType,
	findMaxAndMin,
	kMinutesToHours,
	lengthOfLongestArrayInMatrix,
	dateToDD_MM_YYYY,
	isValidUrl,
	stringInEnglish,
	getHoursPassed,
	getWeekDaysNames,
	getDayHours,
	convertUtcHourToLocalHour,
	convertLocalHourToUtcHour,
	getWeekDayName,
	isCharValid,
	getNumRange,
	getWeeksRange,
	isPastDate,
	isValidEmail,
	flattenArray
}
