const RealTimePanel = () => import('../views/RealTimePanel.vue');

export const realTimeRoutes = [
    {
        path: '/real-time',
        component: RealTimePanel,
        children: [
            { name: 'realTime', path: '/' },
            { name: 'maintenanceRealTime', path: 'maintenance' },
            { name: 'taskRealTime', path: 'task' },
            { name: 'cleanDex', path: 'cleandex' },
        ]
    },
]