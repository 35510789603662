import scssVars from '@/styles/vars.scss';
import { localeData } from '../../common/services/localeService';
const texts = require('../../../texts');

export const analyticsChartColors = {
    likes: ["#C32737", "#4D77A6", "#468437"],
    clicks: ["rgba(195, 39, 55, 1)", "rgba(195, 39, 55, 0.8)", "rgba(195, 39, 55, 0.6)", "rgba(195, 39, 55, 0.4)", "rgba(195, 39, 55, 0.3)", "rgba(195, 39, 55, 0.2)", "rgba(195, 39, 55, 0.1)", "rgba(195, 39, 55, 0.1)"],
    cleaning: ["rgba(195, 39, 55, 1)", "rgba(195, 39, 55, 0.8)", "rgba(195, 39, 55, 0.6)", "rgba(195, 39, 55, 0.4)", "rgba(195, 39, 55, 0.3)", "rgba(195, 39, 55, 0.2)", "rgba(195, 39, 55, 0.1)", "rgba(195, 39, 55, 0.1)"],
    maintenance: ["rgba(77, 119, 166, 1)", "rgba(77, 119, 166, 0.8)", "rgba(77, 119, 166, 0.6)", "rgba(77, 119, 166, 0.4)", "rgba(77, 119, 166, 0.3)", "rgba(77, 119, 166, 0.2)", "rgba(77, 119, 166, 0.1)", "rgba(77, 119, 166, 0.1)"],
}

export const chartColors = {
    avg_score: { line: scssVars.wedgewoodBlue, background: "" },
    complaints: { line: scssVars.persianRed, background: "" },
    visits: { line: scssVars.bouquet, background: "" },
    response_time_SLA: { line: scssVars.flamePea, background: "" },
    complaints_vs_visits: { line: scssVars.jaffa, background: "" },
    time_saved: { line: scssVars.saffron, background: "" },
    arrivals: { line: scssVars.goblinGreen, background: "" }
}

export const byHourTableConst = [
    { color: scssVars.blue, text: 'visits' },
    { color: scssVars.red, text: 'complaints' },
    { color: scssVars.darkerGreen, text: 'cleaner_arrivals' },
];

export const overviewChartOptions = {
    hover: {
        onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";
        },
    },
    maintainAspectRatio: false,
    legend: { display: false },
    layout: { padding: { top: 10 } },
    tooltips: {
        callbacks: {
            label: (tooltipItem, chartData) => {
                const { datasets } = chartData;
                const { label } = datasets[tooltipItem.datasetIndex];
                const labelStr = `${tooltipItem.label} / ${texts[localeData.language][label]}: ${tooltipItem.yLabel}`;
                return labelStr;
            },
            title: (tooltipItems, chartData) => '',
        },
        bodyFontSize: 15,
        backgroundColor: scssVars.lightGrey,
        titleFontColor: scssVars.blackBlue,
        bodyFontColor: scssVars.blackBlue,
        caretPadding: 20,
        displayColors: false,
        rtl: localeData.isRTL
    },
    scales: {
        xAxes: [{ gridLines: { color: "rgba(0, 0, 0, 0)" }, barPercentage: 0.2 }],
        yAxes: []
    }
}

export const overviewLineChartsOptions = {

    maintainAspectRatio: false,
    legend: { display: false },
    layout: { padding: { top: 10 } },
    elements: { point: { radius: 0 } },
    tooltips: {
        callbacks: {
            label: (tooltipItem, chartData) => {
                const { datasets } = chartData;
                const { label } = datasets[tooltipItem.datasetIndex];
                const labelStr = `${tooltipItem.label} / ${texts[localeData.language][label]}: ${tooltipItem.yLabel}`;
                return labelStr;
            },
            title: (tooltipItems, chartData) => '',
        },
        bodyFontSize: 12,
        titleFontSize: 12,
        backgroundColor: scssVars.lightGrey,
        titleFontColor: scssVars.blackBlue,
        bodyFontColor: scssVars.blackBlue,
        displayColors: false,
        rtl: localeData.isRTL,
        position: 'nearest',
        yPadding: 8,
        titleMarginBottom: 1,
        caretPadding: -10,
    },
    scales: {
        xAxes: [{ gridLines: { color: "rgba(0, 0, 0, 0)" }, barPercentage: 0.2, display: false }],
        yAxes: [{ display: false, ticks: { min: 0, max: 105 } }]
    }
};

export const overviewLineChartsColors = {
    avg_score: '#5781AE',
    complaints: '#D64342',
    response_time_SLA: '#D75E41',
    time_saved: '#F6BC45',
    avg_score_static: '#507CAC',
    complaints_static: '#D33736',
    response_time_SLA_static: '#D65A3B',
    time_saved_static: '#F5B839',
};

export const trendArrowsBackgroundColors = {
    avg_score: '#DCE4EE',
    complaints: '#F5D6D5',
    response_time_SLA: '#F7DDD7',
    time_saved: '#FDF0D6',
    avg_score_static: '#83A2C4',
    complaints_static: '#E07170',
    response_time_SLA_static: '#E18A74',
    time_saved_static: '#F8CD72',
};

export const analyticsTableInfo = {
    header: 'visits data',
    head: ['displayName', 'visits', 'complaints', 'arrivals', 'supervisors', 'counterCalls', 'cleandexCalls', 'manualCalls', 'avgResponseTime', 'exposures', 'ratio', 'score']
};

export const analyticsTableInfoNarrowed = {
    header: 'visits data',
    head: ['displayName', 'complaints']
};

export const infoCards = [
    {
        label: 'Avg.Score',
        name: 'avg_score',
        iconUrl: 'assets/performance/avg_score-icon.svg',
        text: 'N/A',
        color: 'red',
        isActive: false
    },
    {
        label: 'Time Saved',
        name: 'time_saved',
        iconUrl: 'assets/performance/clock.svg',
        text: 'N/A',
        color: 'red',
        isActive: false
    },
    {
        label: 'Est.ROI',
        name: 'est_ROI',
        iconUrl: 'assets/performance/dollar.svg',
        text: 'N/A',
        color: 'red',
        isActive: false
    }
];

export const buttonsInfo = [
    {
        label: 'Avg.Score',
        name: 'avg_score',
        iconUrl: 'assets/performance/avg_score-icon.svg',
        color: 'wedgewood-blue',
        isActive: true,
        text: 'N/A'
    },
    {
        label: 'Complaints',
        name: 'complaints',
        iconUrl: 'assets/performance/icons-complaint.svg',
        activeIconUrl: 'assets/performance/icons-complaint_white.svg',
        color: 'persian-red',
        isActive: false,
        text: 'N/A'
    },
    {
        label: 'cleaner_arrivals',
        name: 'arrivals',
        iconUrl: 'assets/performance/cleaner_arrival-icon.svg',
        activeIconUrl: 'assets/performance/cleaner_arrival_white-icon.svg',
        color: 'goblin-green',
        isActive: false,
        text: 'N/A'
    },
    {
        label: 'Visits',
        name: 'visits',
        iconUrl: 'assets/performance/icons-visits.svg',
        activeIconUrl: 'assets/performance/icons-visits_white.svg',
        color: 'bouquet',
        isActive: false,
        text: 'N/A',
        isAmended: 0
    },
    {
        label: 'Response time (SLA)',
        name: 'response_time_SLA',
        iconUrl: 'assets/performance/icons-timer.svg',
        activeIconUrl: 'assets/performance/icons-timer_white.svg',
        color: 'flame-pea',
        isActive: false,
        text: 'N/A'
    },
    {
        label: 'Complaints vs. Visits',
        name: 'complaints_vs_visits',
        iconUrl: null,
        color: 'jaffa',
        isActive: false,
        text: 'N/A'
    },
    {
        label: 'Time Saved',
        name: 'time_saved',
        iconUrl: 'assets/performance/clock.svg',
        activeIconUrl: 'assets/performance/clock_white.svg',
        text: 'N/A',
        color: 'saffron',
        isActive: false
    }
];

export const serviceLogBarItemsInitialMaintenance = [
    {
        label: 'avg_score',
        name: 'avg_score',
        iconUrl: 'assets/performance/avg_score-icon.svg',
        color: 'cyan',
        text: 'N/A'
    },
    {
        label: 'complaints',
        name: 'complaints',
        iconUrl: 'assets/performance/icons-complaint.svg',
        activeIconUrl: 'assets/performance/icons-complaint_white.svg',
        color: 'red',
        text: 'N/A'
    },
    {
        text: 'N/A',
        label: 'maintenance_arrivals',
        name: 'maintenanceArrivals',
        iconUrl: 'assets/performance/maintenance-arrivals_purple.svg',
        
    },
    {
        label: 'visits',
        name: 'visits',
        iconUrl: 'assets/performance/icons-visits.svg',
        activeIconUrl: 'assets/performance/icons-visits_white.svg',
        color: 'blue',
        text: 'N/A'
    },
];

export const serviceLogBarItemsInitialCleaning = [
    {
        label: 'avg_score',
        name: 'avg_score',
        iconUrl: 'assets/performance/avg_score-icon.svg',
        color: 'cyan',
        text: 'N/A'
    },
    {
        label: 'complaints',
        name: 'complaints',
        iconUrl: 'assets/performance/icons-complaint.svg',
        activeIconUrl: 'assets/performance/icons-complaint_white.svg',
        color: 'red',
        text: 'N/A'
    },
    {
        label: 'counterCalls',
        name: 'counterCalls',
        iconUrl: 'assets/performance/icons-counter_calls.svg',
        activeIconUrl: '',
        color: 'orange',
        text: 'N/A'
    },
    {
        label: 'cleandexCalls',
        name: 'cleandexCalls',
        iconUrl: 'assets/performance/cleandex_calls-icon.svg',
        activeIconUrl: '',
        color: 'orange',
        text: 'N/A'
    },
    {
        label: 'manualCalls',
        name: 'manualCalls',
        iconUrl: 'assets/performance/icons-counter_calls.svg',
        activeIconUrl: '',
        color: 'orange',
        text: 'N/A'
    },
    {
        label: 'cleaner_arrivals',
        name: 'arrivals',
        iconUrl: 'assets/performance/cleaner_arrival-icon.svg',
        text: 'N/A'
    },
    {
        text: 'N/A',
        label: 'supervisor_arrival',
        name: 'supervisor_arrival',
        iconUrl: 'assets/performance/supervisor_arrival-icon.svg',
    },
    {
        label: 'visits',
        name: 'visits',
        iconUrl: 'assets/performance/icons-visits.svg',
        activeIconUrl: 'assets/performance/icons-visits_white.svg',
        color: 'blue',
        text: 'N/A'
    },
    {
        label: 'response_time_SLA',
        name: 'response_time_SLA',
        iconUrl: 'assets/performance/icons-timer.svg',
        activeIconUrl: 'assets/performance/icons-timer_white.svg',
        color: 'pink',
        text: 'N/A'
    }
];

export const infoBarItems = [
    {
        text: 'N/A',
        label: 'avg_score',
        name: 'avg_score',
        iconUrl: 'assets/performance/avg_score-icon.svg',
    },
    {
        text: 'N/A',
        label: 'exposures',
        name: 'exposures',
        iconUrl: 'assets/performance/man-exposures.svg',
    },
    {
        text: 'N/A',
        label: 'complaints',
        name: 'complaints',
        iconUrl: 'assets/performance/icons-complaint.svg',
    },
    {
        text: 'N/A',
        label: 'counterCalls',
        name: 'counterCalls',
        iconUrl: 'assets/performance/icons-counter_calls.svg',
    },
    {
        text: 'N/A',
        label: 'cleandexCalls',
        name: 'cleandexCalls',
        iconUrl: 'assets/performance/cleandex_calls-icon.svg',
    },
    {
        text: 'N/A',
        label: 'manualCalls',
        name: 'manualCalls',
        iconUrl: 'assets/performance/icons-counter_calls.svg',
    },
    {
        text: 'N/A',
        label: 'maintenance_complaints',
        name: 'maintenanceComplaint',
        iconUrl: 'assets/performance/icons-complaint_blue.svg',
    },
    {
        text: 'N/A',
        label: 'cleaner_arrivals',
        name: 'arrivals',
        iconUrl: 'assets/performance/cleaner_arrival-icon.svg',
    },
    {
        text: 'N/A',
        label: 'maintenance_arrivals',
        name: 'maintenanceArrivals',
        iconUrl: 'assets/performance/maintenance-arrivals_purple.svg',
    },
    {
        text: 'N/A',
        label: 'supervisor_arrival',
        name: 'supervisor_arrival',
        iconUrl: 'assets/performance/supervisor_arrival-icon.svg',
    },
    {
        text: 'N/A',
        label: 'visits',
        name: 'visits',
        iconUrl: 'assets/performance/icons-visits.svg',
        isAmended: 0
    },
    {
        text: 'N/A',
        label: 'response_time_SLA',
        name: 'response_time_SLA',
        iconUrl: 'assets/performance/icons-timer.svg',
    },
];

export const trendArrowColors = {
    avg_score: {
        up: "green",
        down: "red",
    },
    exposures:{
        up: "green",
        down: "red",
    },
    complaints: {
        up: "red",
        down: "green",
    },
    response_time_SLA: {
        up: "red",
        down: "green",
    },
    time_saved: {
        up: "green",
        down: "red",
    },
}