import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const opts = {
	icons: {
		iconfont: 'md'
	}
}

export default new Vuetify(opts)
