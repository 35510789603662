<template>
  <div id="app" :dir="isRTL">
    <Screen v-if="isScreenVisible" />
    <section class="menus-control">
      <button class="hamburger-btn" @click="toggleSideNav" v-if="!isBlankMode && loggedUser && !isSideNavOpen">
        <img :src="require('@/assets/burger-menu.png')"  alt="menu" />
      </button>
      <button class="close-menu-btn" @click="toggleSideNav" v-if="!isBlankMode && loggedUser && isSideNavOpen">
        <img :src="require('@/assets/close-menu.png')" alt="close" />
      </button>
    </section>
    <Header v-if="!isBlankMode && loggedUser && isSideNavOpen" />
    <!-- inside component there are css properties for print & regular mode -->
    <!-- Hamburger Menu -->
    <section class="view-nav-container">
      <SideNavbar class="side-nav" v-if="!isBlankMode && loggedUser && isSideNavOpen" />
      <FilterCmp v-if="filterOptions"/>
      <section :class="['main', { 'full-width': !isSideNavOpen }]" v-if="$route.name === 'login' || filterOptions">
        <router-view />
      </section>
      <!-- <section class="login-main" v-if="$route.name === 'login' && !filterOptions">
        <router-view />
      </section> -->
    </section>
  </div>
</template>

<script>
import SideNavbar from "./modules/common/components/SideNavbar";
import Header from "./modules/common/components/Header/Header";
import FilterCmp from "./modules/common/components/FilterCmp";
import Screen from "./modules/common/components/Screen";
import { mapGetters } from 'vuex';

export default {
  name: "App",
  data() {
    return {
      isSideNavOpen: true,
    };
  },
  async created() {
    await Promise.all([
      this.$store.dispatch({ type: "updateLocale" }),
    ])
  },
  methods: {
    toggleSideNav() {
      this.isSideNavOpen = !this.isSideNavOpen;
    },
  },
  watch: {
    "$route.params.roomId": function () {
      this.$store.dispatch("getRoomById", { id: this.$route.params.roomId });
    }
  },
  components: {
    SideNavbar,
    Header,
    Screen,
    FilterCmp,
  },
  computed: {
    ...mapGetters(['isBlankMode', 'isScreenVisible', 'loggedUser', 'filterSelected', 'filterOptions']),
    isRTL() {
      return this.$store.getters.isRTL ? "rtl" : "ltr";
    },
  }
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/app.scss";
</style>
