import { localLoad, localStore } from './storageService'


export const localeData = {
    language: 'en',
    isRTL: false
}


export function updateLocaleData(language) {
    if (!language) {
        language = localLoad('language') || 'en'
    }
    localeData.language = language
    localStore("language", language);

    let isRTL = false
    switch (language) {
        case 'he':
            isRTL = true
            break;
            case 'ar':
                isRTL = true
                break;
    }
    localeData.isRTL = isRTL
}