<template>
  <div class="time-filter-container" v-if="filterSelected && filterOptions">
    <template v-if="fields.dateRange">
      <Select
        v-if="this.$route.name === 'overview'"
        :value="'last_month'"
        :disabled="true"
        class="filter-select"
      ></Select>

      <template v-else>
        <Select
          :value="filterSelected.timeType"
          class="filter-select time-type-select"
          @input="handleSelect($event, 'timeType')"
        >
          <Option
            class="time-type-option"
            v-for="type in filterOptions.timeType"
            :key="type"
            :label="$t(type)"
            :value="type"
          />
        </Select>
        <DatePicker
          class="date-picker"
          v-if="filterSelected.timeType === 'custom_time'"
          :value="filterSelected.time.timeFrom"
          @input="handleDateInput($event, 'timeFrom')"
        />
        {{filterSelected.timeType === 'custom_time' ? ' - ': ''}}
        <DatePicker
          class="date-picker"
          v-if="filterSelected.timeType === 'custom_time'"
          :value="filterSelected.time.timeTo"
          @input="handleDateInput($event, 'timeTo')"
        />
      </template>
    </template>

    <Select
      v-if="fields.viewBy"
      @input="handleSelect($event, 'viewTimeBy')"
      :disabled="this.$route.name === 'overview'"
      :value="filterSelected.viewTimeBy"
      class="filter-select"
    >
      <Option
        v-for="item in filterOptions.viewBy"
        :key="item.label"
        :label="$t(item.label)"
        :value="item.value"
      />
    </Select>
  </div>
</template>

<script>
import Select from '../Select';
import Option from '../Option';
import DatePicker from '../DatePicker';
import { mapGetters } from 'vuex';

export default {
  methods: {
    handleSelect(value, field) {
      this.$emit('input', field, value);
    },
    /**
     * @param {string} value
     * @param {'timeFrom' | 'timeTo'} field 
     */
    handleDateInput(value, field) {
      const timeFilter = {
        timeFrom: field === 'timeFrom' ? value : this.filterSelected.time.timeFrom,
        timeTo: field === 'timeTo' ? value : this.filterSelected.time.timeTo
      }
      this.$emit('input', 'time', timeFilter);
    }
  },
  computed: {
    ...mapGetters(['filterOptions', 'filterSelected']),
    fields() {
      let dateRange = true, viewBy = true;
      //   if (this.timeType === "last_quarter" || this.timeType === "last_year") viewBy.splice(viewBy.length - 1, 1);

      const { name, path } = this.$route;
      if (name === "suggestion") viewBy = false;
      if (path.includes("suggestion")) dateRange = false;


      const fields = { dateRange, viewBy };
      return fields;
    },
  },
  components: { Select, Option, DatePicker }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.time-filter-container {
  @include flex-center;
  width: 100%;
  justify-content: flex-end;
  /* text-transform: capitalize; */
  & > * {
    justify-self: center !important;
  }
  .md-datepicker {
    align-self: center !important;
    @include flex-center;
    @include border(#e7e7e7);
    border-radius: 4px;
    margin: 0 !important;
    padding: 0 !important;
    height: 4.25vh;
  }

  .filter-select {
    @include filter-select;
  }
}
</style>