<template>
  <md-datepicker
    @input="emitInput"
    :value="value"
    :md-model-type="String"
    :md-open-on-focus="!disabled"
  />
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: String, default: '' }
  },
  methods: {
    emitInput(value) {
      this.$emit("input", value);
    }
  },
};
</script>