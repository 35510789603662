import { getDevices, getDevicesConnectivity, getDevicesBatteryPower } from '../services/deviceService'

export default {
    state: {
        devices:[],
        devicesConnectivity:{},
        devicesBatteryPower:{}
    },
    getters: {
        devicesStatus(state){
            return state.devices
        },
        devicesConnectivity(state){
            return state.devicesConnectivity
        },
        devicesBatteryPower(state){
            return state.devicesBatteryPower
        }
    },
    mutations: {
        setDevices(state, devices) {
            state.devices = devices;
        },
        setDevicesConnectivity(state, devicesConnectivity) {
            state.devicesConnectivity = devicesConnectivity;
        },
        setDevicesBatteryPower(state, devicesBatteryPower) {
            state.devicesBatteryPower = devicesBatteryPower;
        },
        resetDevicesConnectivity(state) {
            state.devicesConnectivity = {}
        },
        resetDevicesBatteryPower(state) {
            state.devicesBatteryPower = {}
        }
    },
    actions: {
        async getDevices(context, { siteId }) {
            const devices = await getDevices(siteId);
            context.commit('setDevices', devices);
        },
        async getDevicesConnectivity(context, { siteId, dates }) {
            const devicesConnectivity = await getDevicesConnectivity(siteId, dates)
            context.commit('setDevicesConnectivity', devicesConnectivity)
        },
        async getDevicesBatteryPower(context, { filter }) {
            const devicesBatteryPower = await getDevicesBatteryPower(filter)
            context.commit('setDevicesBatteryPower', devicesBatteryPower)
        }
    }
}