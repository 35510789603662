import countryList from './countries.json'
export const countries = countryList

export const sideBarNavItems = [
  {
    name: 'dashboard',
    permission: 'dashboard',
    path: '/dashboard',
  },
  {
    name: 'real_time',
    permission: 'real-time',
    path: '/real-time',
  },
  {
    name: 'survey',
    permission: 'survey',
    path: '/survey',
  },
  {
    name: 'rooms',
    permission: 'room',
    path: '/admin/room/list',
  },
  {
    name: 'task',
    permission: 'task',
    path: '/task',
  },
  {
    name: 'users',
    permission: 'users',
    path: '/users',
  },
  {
    name: 'shifts',
    permission: 'shift',
    path: '/shift',
  },
  {
    name: 'devices',
    permission: 'devices',
    path: '/admin/devices',
  },
  {
    name: 'working_area',
    permission: 'real-time',
    path: '/working-area',
  },
]

export const sideBarAdminNavItems = [
  {
    name: 'franchise',
    permission: 'franchise',
    path: '/admin/franchise',
  },
  {
    name: 'company',
    permission: 'company',
    path: '/admin/company',
  },
  {
    name: 'site',
    permission: 'site',
    path: '/admin/site',
  },
  {
    name: 'user',
    permission: 'user',
    path: '/admin/user',
  },
  {
    name: 'alerts',
    permission: 'device-alerts',
    path: '/admin/device-alerts',
  },
]
