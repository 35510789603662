<template>
  <section
    :class="[
      { active: $store.getters.isAdditionalFilterOpen },
      'filter-container',
    ]"
    v-if="filterOptions"
  >
    <template v-for="(value, field) of filterOptions">
      <transition name="fade" :key="field">
        <FilterSelect
          v-if="fieldsToShow[field] && shouldRenderSelect"
          @select="handleSelect"
          @multiSelect="handleMultiSelect"
          :value="value"
          :field="field"
        />
      </transition>
    </template>
    <Button class="filter-btn" skin="primary" @click="activateFilter">{{
      $t("filter")
    }}</Button>
    <Button class="filter-btn" skin="primary" @click="clearFilter">{{
      $t("reset_filter")
    }}</Button>
  </section>
</template>

<script>
import FilterSelect from './Filter/FilterSelect';
import Button from './Button';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      shouldRenderSelect: true,
    }
  },
  methods: {
    handleMultiSelect(value, field) {
      const valueCopy = JSON.parse(JSON.stringify(value));
      this.$store.commit({ type: 'updateFilter', field, value: valueCopy });
    },
    handleSelect(value, field) {
      this.$store.commit({ type: 'updateFilter', field, value });
    },
    async activateFilter() {
      const { roomId } = this.$route.params;
      this.$store.commit({ type: 'toggleLoading', value: true });
      const filter = {
        ...this.filterSelected,
        roomId: roomId ? roomId : '',
        language: this.$i18n.locale
      }
      await Promise.all([
        this.$store.dispatch({ type: 'getOverviewData', filter }),
        this.$store.dispatch({ type: 'getAnalyticsData', filter })
      ])
      this.setActiveFilter()
      this.$store.commit({ type: 'toggleLoading', value: false });
    },
    clearFilter() {
      this.shouldRenderSelect = false;
      this.resetSelectedFilterProperties()
      this.activateFilter();
      setTimeout(() => this.shouldRenderSelect = true, 0);
    },
    setActiveFilter() {
      let filter = JSON.parse(JSON.stringify(this.filterSelected))
      delete filter.company;
      delete filter.time;
      delete filter.viewTimeBy;
      delete filter.timeType;
      delete filter.site;
      delete filter.language;
      const isActive = Object.values(filter).some(element => element !== null)
      return this.$store.commit({ type: 'setFilterStatus', value: isActive })
    },
    resetSelectedFilterProperties() {
      for (const property in this.filterSelected) {
        if (!this.activeFilter[property]) continue
        this.handleSelect(null, property)
      }
      this.handleSelect(null, 'hour')
    },
    resetAndHideFilter() {
      this.resetSelectedFilterProperties()
      this.$store.commit({ type: 'toggleAdditionalFilter', value: false})
    }
  },
  computed: {
    ...mapGetters(['filterOptions', 'filterSelected']),
    fieldsToShow() {
      if (['overview', 'suggestion', 'performance', 'analytics'].includes(this.$route.name)) {
        return {
          rooms: true,
          building: true,
          floor: true,
          gender: true,
          shift: true,
          days: true,
          servikName: true
        }
      } else if (['cleanLog', 'maintenanceLog', 'roomAnalytics', 'roomAnalyticsLog', 'cleanerArrival','roomExposures'].includes(this.$route.name)) {
        return {
          shift: true,
          days: true
        }
      }
      return {}
    },
    companyAndSite() {
      return `${this.filterSelected.company}|${this.filterSelected.site}`;
    },
    activeFilter() {
      return this.$store.getters.additionalActiveFilter
    }
  },
  watch: {
    companyAndSite() {
      this.shouldRenderSelect = false;
      setTimeout(() => this.shouldRenderSelect = true, 0);
    },
    "$route.path": function () {
      if (this.$route.path.includes('dashboard')) return
      this.resetAndHideFilter()
    },
  },
  components: { FilterSelect, Button }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.filter-container {
  height: 4.7vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-row: 1/2;
  padding-inline-start: 20px;
  padding-inline-end: 40px;
  box-shadow: 0px 0px 1px #bdbdbd;
  position: relative;
  top: -1000px;
  transition: top 0.2s;
  background-color: $white;
  box-shadow: 1px 1px 4px #00000026;
  z-index: 12;
  overflow-y: hidden;
  grid-column: 1/16;
  transition: top 2s;
  &.small {
    grid-column: 11/16;
  }
  &.active {
    transition: top 0.5s;
    top: 0;
  }
}
.filter-btn {
  font-weight: $font-weight-bold;
}
</style>